import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IZZZBangboo } from '../../../common/model/graphql-types';
import { ZZZBangbooCard } from './zzz-bangboo-card';
import { ZZZBangbooIcon } from './zzz-bangboo-icon';

interface IProps {
  id?: string;
  slug: string;
  showLabel?: boolean;
  mode?: string;
  unitId?: string;
}

export const ZZZBangboo: React.FC<IProps> = ({
  id,
  unitId,
  mode,
  showLabel,
  slug
}) => {
  const allZzzBangboo = useStaticQuery(graphql`
    query {
      allContentfulZzzBangboo {
        nodes {
          id
          unitId
          slug
          name
          rarity
          smallImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          cardImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  if ((!id && !slug && !unitId) || !mode) {
    return <div>Err</div>;
  }

  const character = allZzzBangboo.allContentfulZzzBangboo.nodes.find(
    (emp: IZZZBangboo) =>
      id ? emp.id === id : unitId ? emp.unitId === unitId : emp.slug === slug
  );

  if (!character) {
    return <div>Err</div>;
  }

  return (
    <>
      {mode === 'card' && (
        <>
          <ZZZBangbooCard employee={character} showLabel={showLabel} />
        </>
      )}
      {mode === 'icon' && (
        <>
          <ZZZBangbooIcon employee={character} showLabel={showLabel} />
        </>
      )}
    </>
  );
};
