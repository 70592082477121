export const ShiyuDuos = [
  {
    char: 'billy-kid',
    round: 2.47,
    char_1: 'nicole-demara',
    app_rate_1: 48.0,
    avg_round_1: 2.53,
    app_flat_1: 0,
    char_2: 'anby-demara',
    app_rate_2: 40.0,
    avg_round_2: 2.57,
    app_flat_2: 0,
    char_3: 'Lucy',
    app_rate_3: 21.33,
    avg_round_3: 2.75,
    app_flat_3: 0,
    char_4: 'Qingyi',
    app_rate_4: 20.0,
    avg_round_4: 2.45,
    app_flat_4: 0,
    char_5: 'Piper',
    app_rate_5: 12.0,
    avg_round_5: 2.44,
    app_flat_5: 0,
    char_6: 'Corin',
    app_rate_6: 10.67,
    avg_round_6: 2.12,
    app_flat_6: 0,
    char_7: 'Rina',
    app_rate_7: 6.67,
    avg_round_7: 3,
    app_flat_7: 0,
    char_8: 'Soukaku',
    app_rate_8: 5.33,
    avg_round_8: 1.25,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 5.33,
    avg_round_9: 2.67,
    app_flat_9: 0,
    char_10: 'grace-howard',
    app_rate_10: 4.0,
    avg_round_10: 2,
    app_flat_10: 0,
    char_11: 'Lycaon',
    app_rate_11: 2.67,
    avg_round_11: 1,
    app_flat_11: 0,
    char_12: 'Ben',
    app_rate_12: 2.67,
    avg_round_12: 3,
    app_flat_12: 0,
    char_13: 'Koleda',
    app_rate_13: 2.67,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: 'Seth',
    app_rate_14: 2.67,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'zhu-yuan',
    app_rate_15: 1.33,
    avg_round_15: 1,
    app_flat_15: 0,
    char_16: 'Anton',
    app_rate_16: 1.33,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 1.33,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Corin',
    round: 2.52,
    char_1: 'Qingyi',
    app_rate_1: 30.29,
    avg_round_1: 2.66,
    app_flat_1: 0,
    char_2: 'Rina',
    app_rate_2: 28.57,
    avg_round_2: 2.74,
    app_flat_2: 0,
    char_3: 'Lycaon',
    app_rate_3: 26.86,
    avg_round_3: 2.47,
    app_flat_3: 0,
    char_4: 'Lucy',
    app_rate_4: 22.29,
    avg_round_4: 2.5,
    app_flat_4: 0,
    char_5: 'Piper',
    app_rate_5: 21.71,
    avg_round_5: 2.68,
    app_flat_5: 0,
    char_6: 'Soukaku',
    app_rate_6: 19.43,
    avg_round_6: 2.41,
    app_flat_6: 0,
    char_7: 'Koleda',
    app_rate_7: 8.57,
    avg_round_7: 2.57,
    app_flat_7: 0,
    char_8: 'anby-demara',
    app_rate_8: 7.43,
    avg_round_8: 2.62,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 6.86,
    avg_round_9: 2.18,
    app_flat_9: 0,
    char_10: 'Ellen',
    app_rate_10: 6.29,
    avg_round_10: 2.22,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 5.14,
    avg_round_11: 2.4,
    app_flat_11: 0,
    char_12: 'billy-kid',
    app_rate_12: 4.57,
    avg_round_12: 2.12,
    app_flat_12: 0,
    char_13: 'Seth',
    app_rate_13: 3.43,
    avg_round_13: 2.25,
    app_flat_13: 0,
    char_14: 'Nekomata',
    app_rate_14: 2.86,
    avg_round_14: 2.4,
    app_flat_14: 0,
    char_15: 'soldier-11',
    app_rate_15: 1.71,
    avg_round_15: 1.33,
    app_flat_15: 0,
    char_16: 'Ben',
    app_rate_16: 1.71,
    avg_round_16: 2.33,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Ben',
    round: 2.59,
    char_1: 'soldier-11',
    app_rate_1: 67.47,
    avg_round_1: 2.63,
    app_flat_1: 0,
    char_2: 'Lucy',
    app_rate_2: 56.02,
    avg_round_2: 2.6,
    app_flat_2: 0,
    char_3: 'Koleda',
    app_rate_3: 27.71,
    avg_round_3: 2.57,
    app_flat_3: 0,
    char_4: 'Piper',
    app_rate_4: 10.84,
    avg_round_4: 2.44,
    app_flat_4: 0,
    char_5: 'Anton',
    app_rate_5: 5.42,
    avg_round_5: 2.38,
    app_flat_5: 0,
    char_6: 'nicole-demara',
    app_rate_6: 5.42,
    avg_round_6: 2.67,
    app_flat_6: 0,
    char_7: 'Qingyi',
    app_rate_7: 4.22,
    avg_round_7: 2.75,
    app_flat_7: 0,
    char_8: 'Soukaku',
    app_rate_8: 3.01,
    avg_round_8: 2.6,
    app_flat_8: 0,
    char_9: 'Ellen',
    app_rate_9: 3.01,
    avg_round_9: 2.75,
    app_flat_9: 0,
    char_10: 'jane-doe',
    app_rate_10: 3.01,
    avg_round_10: 2.75,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 2.41,
    avg_round_11: 2.5,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 1.81,
    avg_round_12: 2.5,
    app_flat_12: 0,
    char_13: 'Lycaon',
    app_rate_13: 1.81,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: 'Corin',
    app_rate_14: 1.81,
    avg_round_14: 2.33,
    app_flat_14: 0,
    char_15: 'billy-kid',
    app_rate_15: 1.2,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: 'anby-demara',
    app_rate_16: 1.2,
    avg_round_16: 2.5,
    app_flat_16: 0,
    char_17: 'Seth',
    app_rate_17: 0.6,
    avg_round_17: 1,
    app_flat_17: 0,
    char_18: 'Nekomata',
    app_rate_18: 0.6,
    avg_round_18: 2,
    app_flat_18: 0,
    char_19: 'Rina',
    app_rate_19: 0.6,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Nekomata',
    round: 2.64,
    char_1: 'Piper',
    app_rate_1: 41.87,
    avg_round_1: 2.71,
    app_flat_1: 0,
    char_2: 'nicole-demara',
    app_rate_2: 41.87,
    avg_round_2: 2.64,
    app_flat_2: 0,
    char_3: 'Lucy',
    app_rate_3: 36.68,
    avg_round_3: 2.72,
    app_flat_3: 0,
    char_4: 'anby-demara',
    app_rate_4: 19.38,
    avg_round_4: 2.58,
    app_flat_4: 0,
    char_5: 'Qingyi',
    app_rate_5: 19.03,
    avg_round_5: 2.73,
    app_flat_5: 0,
    char_6: 'jane-doe',
    app_rate_6: 15.22,
    avg_round_6: 2.54,
    app_flat_6: 0,
    char_7: 'Seth',
    app_rate_7: 6.23,
    avg_round_7: 2.43,
    app_flat_7: 0,
    char_8: 'Koleda',
    app_rate_8: 5.88,
    avg_round_8: 2.29,
    app_flat_8: 0,
    char_9: 'Rina',
    app_rate_9: 3.46,
    avg_round_9: 2.78,
    app_flat_9: 0,
    char_10: 'Lycaon',
    app_rate_10: 1.73,
    avg_round_10: 3,
    app_flat_10: 0,
    char_11: 'Soukaku',
    app_rate_11: 1.73,
    avg_round_11: 2.75,
    app_flat_11: 0,
    char_12: 'Corin',
    app_rate_12: 1.73,
    avg_round_12: 2.4,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 1.38,
    avg_round_13: 2.25,
    app_flat_13: 0,
    char_14: 'soldier-11',
    app_rate_14: 0.69,
    avg_round_14: 1.5,
    app_flat_14: 0,
    char_15: 'Ellen',
    app_rate_15: 0.35,
    avg_round_15: 1,
    app_flat_15: 0,
    char_16: 'Ben',
    app_rate_16: 0.35,
    avg_round_16: 2,
    app_flat_16: 0,
    char_17: 'billy-kid',
    app_rate_17: 0.35,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'grace-howard',
    app_rate_18: 0.35,
    avg_round_18: 1,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Anton',
    round: 2.64,
    char_1: 'Qingyi',
    app_rate_1: 50.78,
    avg_round_1: 2.74,
    app_flat_1: 0,
    char_2: 'Rina',
    app_rate_2: 47.15,
    avg_round_2: 2.61,
    app_flat_2: 0,
    char_3: 'grace-howard',
    app_rate_3: 36.79,
    avg_round_3: 2.61,
    app_flat_3: 0,
    char_4: 'anby-demara',
    app_rate_4: 19.17,
    avg_round_4: 2.49,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 13.99,
    avg_round_5: 2.88,
    app_flat_5: 0,
    char_6: 'Koleda',
    app_rate_6: 10.88,
    avg_round_6: 2.62,
    app_flat_6: 0,
    char_7: 'Lucy',
    app_rate_7: 5.7,
    avg_round_7: 2.88,
    app_flat_7: 0,
    char_8: 'Ben',
    app_rate_8: 4.66,
    avg_round_8: 2.38,
    app_flat_8: 0,
    char_9: 'Soukaku',
    app_rate_9: 3.11,
    avg_round_9: 2.75,
    app_flat_9: 0,
    char_10: 'jane-doe',
    app_rate_10: 2.07,
    avg_round_10: 2.75,
    app_flat_10: 0,
    char_11: 'Lycaon',
    app_rate_11: 1.55,
    avg_round_11: 2.33,
    app_flat_11: 0,
    char_12: 'Ellen',
    app_rate_12: 1.55,
    avg_round_12: 2.67,
    app_flat_12: 0,
    char_13: 'Seth',
    app_rate_13: 1.04,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: 'soldier-11',
    app_rate_14: 1.04,
    avg_round_14: 2,
    app_flat_14: 0,
    char_15: 'billy-kid',
    app_rate_15: 0.52,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'anby-demara',
    round: 2.67,
    char_1: 'nicole-demara',
    app_rate_1: 51.24,
    avg_round_1: 2.68,
    app_flat_1: 0,
    char_2: 'zhu-yuan',
    app_rate_2: 44.15,
    avg_round_2: 2.7,
    app_flat_2: 0,
    char_3: 'Ellen',
    app_rate_3: 30.15,
    avg_round_3: 2.62,
    app_flat_3: 0,
    char_4: 'Soukaku',
    app_rate_4: 29.38,
    avg_round_4: 2.64,
    app_flat_4: 0,
    char_5: 'jane-doe',
    app_rate_5: 7.26,
    avg_round_5: 2.75,
    app_flat_5: 0,
    char_6: 'Lucy',
    app_rate_6: 5.98,
    avg_round_6: 2.87,
    app_flat_6: 0,
    char_7: 'grace-howard',
    app_rate_7: 5.04,
    avg_round_7: 2.45,
    app_flat_7: 0,
    char_8: 'Nekomata',
    app_rate_8: 4.78,
    avg_round_8: 2.58,
    app_flat_8: 0,
    char_9: 'Rina',
    app_rate_9: 4.78,
    avg_round_9: 2.4,
    app_flat_9: 0,
    char_10: 'Seth',
    app_rate_10: 4.01,
    avg_round_10: 2.76,
    app_flat_10: 0,
    char_11: 'soldier-11',
    app_rate_11: 3.84,
    avg_round_11: 2.82,
    app_flat_11: 0,
    char_12: 'Anton',
    app_rate_12: 3.16,
    avg_round_12: 2.49,
    app_flat_12: 0,
    char_13: 'billy-kid',
    app_rate_13: 2.56,
    avg_round_13: 2.57,
    app_flat_13: 0,
    char_14: 'Piper',
    app_rate_14: 1.71,
    avg_round_14: 2.6,
    app_flat_14: 0,
    char_15: 'Corin',
    app_rate_15: 1.11,
    avg_round_15: 2.62,
    app_flat_15: 0,
    char_16: 'Koleda',
    app_rate_16: 0.17,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'Qingyi',
    app_rate_17: 0.17,
    avg_round_17: 2,
    app_flat_17: 0,
    char_18: 'Ben',
    app_rate_18: 0.17,
    avg_round_18: 2.5,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Piper',
    round: 2.67,
    char_1: 'Lucy',
    app_rate_1: 67.81,
    avg_round_1: 2.68,
    app_flat_1: 0,
    char_2: 'Nekomata',
    app_rate_2: 24.35,
    avg_round_2: 2.71,
    app_flat_2: 0,
    char_3: 'jane-doe',
    app_rate_3: 22.54,
    avg_round_3: 2.78,
    app_flat_3: 0,
    char_4: 'Koleda',
    app_rate_4: 14.89,
    avg_round_4: 2.55,
    app_flat_4: 0,
    char_5: 'soldier-11',
    app_rate_5: 13.08,
    avg_round_5: 2.6,
    app_flat_5: 0,
    char_6: 'grace-howard',
    app_rate_6: 10.26,
    avg_round_6: 2.72,
    app_flat_6: 0,
    char_7: 'nicole-demara',
    app_rate_7: 8.25,
    avg_round_7: 2.69,
    app_flat_7: 0,
    char_8: 'Corin',
    app_rate_8: 7.65,
    avg_round_8: 2.68,
    app_flat_8: 0,
    char_9: 'Rina',
    app_rate_9: 6.84,
    avg_round_9: 2.74,
    app_flat_9: 0,
    char_10: 'Seth',
    app_rate_10: 6.64,
    avg_round_10: 2.75,
    app_flat_10: 0,
    char_11: 'anby-demara',
    app_rate_11: 4.02,
    avg_round_11: 2.6,
    app_flat_11: 0,
    char_12: 'Qingyi',
    app_rate_12: 3.82,
    avg_round_12: 2.79,
    app_flat_12: 0,
    char_13: 'Ben',
    app_rate_13: 3.62,
    avg_round_13: 2.44,
    app_flat_13: 0,
    char_14: 'billy-kid',
    app_rate_14: 1.81,
    avg_round_14: 2.44,
    app_flat_14: 0,
    char_15: 'Lycaon',
    app_rate_15: 1.41,
    avg_round_15: 2.71,
    app_flat_15: 0,
    char_16: 'Soukaku',
    app_rate_16: 1.21,
    avg_round_16: 2,
    app_flat_16: 0,
    char_17: 'Ellen',
    app_rate_17: 0.6,
    avg_round_17: 1,
    app_flat_17: 0,
    char_18: 'zhu-yuan',
    app_rate_18: 0.4,
    avg_round_18: 2,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'grace-howard',
    round: 2.69,
    char_1: 'jane-doe',
    app_rate_1: 47.53,
    avg_round_1: 2.81,
    app_flat_1: 0,
    char_2: 'Rina',
    app_rate_2: 28.47,
    avg_round_2: 2.78,
    app_flat_2: 0,
    char_3: 'Seth',
    app_rate_3: 28.24,
    avg_round_3: 2.72,
    app_flat_3: 0,
    char_4: 'Anton',
    app_rate_4: 16.71,
    avg_round_4: 2.61,
    app_flat_4: 0,
    char_5: 'anby-demara',
    app_rate_5: 13.88,
    avg_round_5: 2.45,
    app_flat_5: 0,
    char_6: 'Piper',
    app_rate_6: 12.0,
    avg_round_6: 2.72,
    app_flat_6: 0,
    char_7: 'Qingyi',
    app_rate_7: 10.82,
    avg_round_7: 2.52,
    app_flat_7: 0,
    char_8: 'Lucy',
    app_rate_8: 10.35,
    avg_round_8: 2.72,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 8.71,
    avg_round_9: 2.77,
    app_flat_9: 0,
    char_10: 'Koleda',
    app_rate_10: 5.88,
    avg_round_10: 2.68,
    app_flat_10: 0,
    char_11: 'Soukaku',
    app_rate_11: 5.41,
    avg_round_11: 2.43,
    app_flat_11: 0,
    char_12: 'Ellen',
    app_rate_12: 4.24,
    avg_round_12: 2.6,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 2.35,
    avg_round_13: 2.6,
    app_flat_13: 0,
    char_14: 'soldier-11',
    app_rate_14: 1.88,
    avg_round_14: 2.62,
    app_flat_14: 0,
    char_15: 'Lycaon',
    app_rate_15: 1.18,
    avg_round_15: 2.8,
    app_flat_15: 0,
    char_16: 'Ben',
    app_rate_16: 0.94,
    avg_round_16: 2.5,
    app_flat_16: 0,
    char_17: 'billy-kid',
    app_rate_17: 0.71,
    avg_round_17: 2,
    app_flat_17: 0,
    char_18: 'Nekomata',
    app_rate_18: 0.24,
    avg_round_18: 1,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'soldier-11',
    round: 2.71,
    char_1: 'Lucy',
    app_rate_1: 82.61,
    avg_round_1: 2.73,
    app_flat_1: 0,
    char_2: 'Koleda',
    app_rate_2: 36.29,
    avg_round_2: 2.76,
    app_flat_2: 0,
    char_3: 'Ben',
    app_rate_3: 21.17,
    avg_round_3: 2.63,
    app_flat_3: 0,
    char_4: 'Qingyi',
    app_rate_4: 21.17,
    avg_round_4: 2.77,
    app_flat_4: 0,
    char_5: 'Piper',
    app_rate_5: 12.29,
    avg_round_5: 2.6,
    app_flat_5: 0,
    char_6: 'anby-demara',
    app_rate_6: 8.51,
    avg_round_6: 2.82,
    app_flat_6: 0,
    char_7: 'nicole-demara',
    app_rate_7: 5.67,
    avg_round_7: 2.63,
    app_flat_7: 0,
    char_8: 'Lycaon',
    app_rate_8: 2.65,
    avg_round_8: 2.5,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 2.46,
    avg_round_9: 2.75,
    app_flat_9: 0,
    char_10: 'grace-howard',
    app_rate_10: 1.51,
    avg_round_10: 2.62,
    app_flat_10: 0,
    char_11: 'Rina',
    app_rate_11: 1.32,
    avg_round_11: 2.5,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 0.95,
    avg_round_12: 2.8,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 0.76,
    avg_round_13: 2.33,
    app_flat_13: 0,
    char_14: 'Corin',
    app_rate_14: 0.57,
    avg_round_14: 1.33,
    app_flat_14: 0,
    char_15: 'Seth',
    app_rate_15: 0.38,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: 'Nekomata',
    app_rate_16: 0.38,
    avg_round_16: 1.5,
    app_flat_16: 0,
    char_17: 'Anton',
    app_rate_17: 0.38,
    avg_round_17: 2,
    app_flat_17: 0,
    char_18: 'Ellen',
    app_rate_18: 0.19,
    avg_round_18: 2,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Ellen',
    round: 2.72,
    char_1: 'Soukaku',
    app_rate_1: 92.96,
    avg_round_1: 2.73,
    app_flat_1: 0,
    char_2: 'Lycaon',
    app_rate_2: 55.86,
    avg_round_2: 2.75,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 14.88,
    avg_round_3: 2.62,
    app_flat_3: 0,
    char_4: 'Qingyi',
    app_rate_4: 9.57,
    avg_round_4: 2.6,
    app_flat_4: 0,
    char_5: 'Rina',
    app_rate_5: 8.98,
    avg_round_5: 2.67,
    app_flat_5: 0,
    char_6: 'Koleda',
    app_rate_6: 8.85,
    avg_round_6: 2.8,
    app_flat_6: 0,
    char_7: 'Lucy',
    app_rate_7: 2.45,
    avg_round_7: 2.7,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 1.81,
    avg_round_8: 2.85,
    app_flat_8: 0,
    char_9: 'grace-howard',
    app_rate_9: 0.76,
    avg_round_9: 2.6,
    app_flat_9: 0,
    char_10: 'Corin',
    app_rate_10: 0.46,
    avg_round_10: 2.22,
    app_flat_10: 0,
    char_11: 'Ben',
    app_rate_11: 0.21,
    avg_round_11: 2.75,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 0.21,
    avg_round_12: 1.75,
    app_flat_12: 0,
    char_13: 'Piper',
    app_rate_13: 0.13,
    avg_round_13: 1,
    app_flat_13: 0,
    char_14: 'jane-doe',
    app_rate_14: 0.13,
    avg_round_14: 2,
    app_flat_14: 0,
    char_15: 'Anton',
    app_rate_15: 0.13,
    avg_round_15: 2.67,
    app_flat_15: 0,
    char_16: 'Seth',
    app_rate_16: 0.08,
    avg_round_16: 2.5,
    app_flat_16: 0,
    char_17: 'soldier-11',
    app_rate_17: 0.04,
    avg_round_17: 2,
    app_flat_17: 0,
    char_18: 'Nekomata',
    app_rate_18: 0.04,
    avg_round_18: 1,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Soukaku',
    round: 2.72,
    char_1: 'Ellen',
    app_rate_1: 95.21,
    avg_round_1: 2.73,
    app_flat_1: 0,
    char_2: 'Lycaon',
    app_rate_2: 55.96,
    avg_round_2: 2.75,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 14.85,
    avg_round_3: 2.64,
    app_flat_3: 0,
    char_4: 'Koleda',
    app_rate_4: 8.89,
    avg_round_4: 2.81,
    app_flat_4: 0,
    char_5: 'Qingyi',
    app_rate_5: 8.85,
    avg_round_5: 2.61,
    app_flat_5: 0,
    char_6: 'Rina',
    app_rate_6: 4.62,
    avg_round_6: 2.78,
    app_flat_6: 0,
    char_7: 'Lucy',
    app_rate_7: 2.03,
    avg_round_7: 2.77,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 1.81,
    avg_round_8: 2.87,
    app_flat_8: 0,
    char_9: 'Corin',
    app_rate_9: 1.47,
    avg_round_9: 2.41,
    app_flat_9: 0,
    char_10: 'grace-howard',
    app_rate_10: 0.99,
    avg_round_10: 2.43,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 0.82,
    avg_round_11: 2.86,
    app_flat_11: 0,
    char_12: 'Seth',
    app_rate_12: 0.6,
    avg_round_12: 2.91,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 0.39,
    avg_round_13: 2.57,
    app_flat_13: 0,
    char_14: 'Piper',
    app_rate_14: 0.26,
    avg_round_14: 2,
    app_flat_14: 0,
    char_15: 'Anton',
    app_rate_15: 0.26,
    avg_round_15: 2.75,
    app_flat_15: 0,
    char_16: 'Ben',
    app_rate_16: 0.22,
    avg_round_16: 2.6,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 0.22,
    avg_round_17: 2.75,
    app_flat_17: 0,
    char_18: 'soldier-11',
    app_rate_18: 0.22,
    avg_round_18: 2.8,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 0.17,
    avg_round_19: 1.25,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Koleda',
    round: 2.72,
    char_1: 'Lucy',
    app_rate_1: 35.68,
    avg_round_1: 2.73,
    app_flat_1: 0,
    char_2: 'Ellen',
    app_rate_2: 30.84,
    avg_round_2: 2.8,
    app_flat_2: 0,
    char_3: 'Soukaku',
    app_rate_3: 30.25,
    avg_round_3: 2.81,
    app_flat_3: 0,
    char_4: 'soldier-11',
    app_rate_4: 28.19,
    avg_round_4: 2.76,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 15.12,
    avg_round_5: 2.76,
    app_flat_5: 0,
    char_6: 'zhu-yuan',
    app_rate_6: 13.36,
    avg_round_6: 2.71,
    app_flat_6: 0,
    char_7: 'Piper',
    app_rate_7: 10.87,
    avg_round_7: 2.55,
    app_flat_7: 0,
    char_8: 'jane-doe',
    app_rate_8: 9.1,
    avg_round_8: 2.67,
    app_flat_8: 0,
    char_9: 'Ben',
    app_rate_9: 6.75,
    avg_round_9: 2.57,
    app_flat_9: 0,
    char_10: 'Seth',
    app_rate_10: 3.96,
    avg_round_10: 2.7,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 3.67,
    avg_round_11: 2.68,
    app_flat_11: 0,
    char_12: 'Anton',
    app_rate_12: 3.08,
    avg_round_12: 2.62,
    app_flat_12: 0,
    char_13: 'Rina',
    app_rate_13: 2.79,
    avg_round_13: 2.73,
    app_flat_13: 0,
    char_14: 'Nekomata',
    app_rate_14: 2.5,
    avg_round_14: 2.29,
    app_flat_14: 0,
    char_15: 'Corin',
    app_rate_15: 2.2,
    avg_round_15: 2.57,
    app_flat_15: 0,
    char_16: 'Qingyi',
    app_rate_16: 0.29,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'billy-kid',
    app_rate_17: 0.29,
    avg_round_17: 2,
    app_flat_17: 0,
    char_18: 'anby-demara',
    app_rate_18: 0.29,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'Lycaon',
    app_rate_19: 0.15,
    avg_round_19: 2,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lycaon',
    round: 2.73,
    char_1: 'Ellen',
    app_rate_1: 90.75,
    avg_round_1: 2.75,
    app_flat_1: 0,
    char_2: 'Soukaku',
    app_rate_2: 88.77,
    avg_round_2: 2.75,
    app_flat_2: 0,
    char_3: 'Rina',
    app_rate_3: 5.34,
    avg_round_3: 2.76,
    app_flat_3: 0,
    char_4: 'Corin',
    app_rate_4: 3.22,
    avg_round_4: 2.47,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 3.22,
    avg_round_5: 2.66,
    app_flat_5: 0,
    char_6: 'zhu-yuan',
    app_rate_6: 2.05,
    avg_round_6: 2.62,
    app_flat_6: 0,
    char_7: 'Lucy',
    app_rate_7: 1.99,
    avg_round_7: 2.65,
    app_flat_7: 0,
    char_8: 'soldier-11',
    app_rate_8: 0.96,
    avg_round_8: 2.5,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 0.82,
    avg_round_9: 2.7,
    app_flat_9: 0,
    char_10: 'Piper',
    app_rate_10: 0.48,
    avg_round_10: 2.71,
    app_flat_10: 0,
    char_11: 'Nekomata',
    app_rate_11: 0.34,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 0.34,
    avg_round_12: 2.8,
    app_flat_12: 0,
    char_13: 'Seth',
    app_rate_13: 0.27,
    avg_round_13: 3,
    app_flat_13: 0,
    char_14: 'Ben',
    app_rate_14: 0.21,
    avg_round_14: 2,
    app_flat_14: 0,
    char_15: 'Anton',
    app_rate_15: 0.21,
    avg_round_15: 2.33,
    app_flat_15: 0,
    char_16: 'billy-kid',
    app_rate_16: 0.14,
    avg_round_16: 1,
    app_flat_16: 0,
    char_17: 'Qingyi',
    app_rate_17: 0.14,
    avg_round_17: 1,
    app_flat_17: 0,
    char_18: 'Koleda',
    app_rate_18: 0.07,
    avg_round_18: 2,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'zhu-yuan',
    round: 2.74,
    char_1: 'nicole-demara',
    app_rate_1: 96.83,
    avg_round_1: 2.75,
    app_flat_1: 0,
    char_2: 'Qingyi',
    app_rate_2: 68.21,
    avg_round_2: 2.77,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 23.72,
    avg_round_3: 2.7,
    app_flat_3: 0,
    char_4: 'Koleda',
    app_rate_4: 4.17,
    avg_round_4: 2.71,
    app_flat_4: 0,
    char_5: 'Lycaon',
    app_rate_5: 1.38,
    avg_round_5: 2.62,
    app_flat_5: 0,
    char_6: 'Rina',
    app_rate_6: 1.33,
    avg_round_6: 2.84,
    app_flat_6: 0,
    char_7: 'Lucy',
    app_rate_7: 1.28,
    avg_round_7: 2.48,
    app_flat_7: 0,
    char_8: 'jane-doe',
    app_rate_8: 0.55,
    avg_round_8: 2.6,
    app_flat_8: 0,
    char_9: 'grace-howard',
    app_rate_9: 0.46,
    avg_round_9: 2.6,
    app_flat_9: 0,
    char_10: 'Soukaku',
    app_rate_10: 0.41,
    avg_round_10: 2.57,
    app_flat_10: 0,
    char_11: 'Seth',
    app_rate_11: 0.28,
    avg_round_11: 2.5,
    app_flat_11: 0,
    char_12: 'Ellen',
    app_rate_12: 0.23,
    avg_round_12: 1.75,
    app_flat_12: 0,
    char_13: 'Nekomata',
    app_rate_13: 0.18,
    avg_round_13: 2.25,
    app_flat_13: 0,
    char_14: 'soldier-11',
    app_rate_14: 0.18,
    avg_round_14: 2.33,
    app_flat_14: 0,
    char_15: 'Ben',
    app_rate_15: 0.14,
    avg_round_15: 2.5,
    app_flat_15: 0,
    char_16: 'Piper',
    app_rate_16: 0.09,
    avg_round_16: 2,
    app_flat_16: 0,
    char_17: 'billy-kid',
    app_rate_17: 0.05,
    avg_round_17: 1,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'nicole-demara',
    round: 2.74,
    char_1: 'zhu-yuan',
    app_rate_1: 85.64,
    avg_round_1: 2.75,
    app_flat_1: 0,
    char_2: 'Qingyi',
    app_rate_2: 62.23,
    avg_round_2: 2.78,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 24.34,
    avg_round_3: 2.68,
    app_flat_3: 0,
    char_4: 'Nekomata',
    app_rate_4: 4.91,
    avg_round_4: 2.64,
    app_flat_4: 0,
    char_5: 'Koleda',
    app_rate_5: 4.18,
    avg_round_5: 2.76,
    app_flat_5: 0,
    char_6: 'jane-doe',
    app_rate_6: 2.19,
    avg_round_6: 2.71,
    app_flat_6: 0,
    char_7: 'Lycaon',
    app_rate_7: 1.91,
    avg_round_7: 2.66,
    app_flat_7: 0,
    char_8: 'Ellen',
    app_rate_8: 1.74,
    avg_round_8: 2.85,
    app_flat_8: 0,
    char_9: 'Soukaku',
    app_rate_9: 1.7,
    avg_round_9: 2.87,
    app_flat_9: 0,
    char_10: 'Piper',
    app_rate_10: 1.66,
    avg_round_10: 2.69,
    app_flat_10: 0,
    char_11: 'Lucy',
    app_rate_11: 1.62,
    avg_round_11: 2.59,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 1.5,
    avg_round_12: 2.77,
    app_flat_12: 0,
    char_13: 'billy-kid',
    app_rate_13: 1.46,
    avg_round_13: 2.53,
    app_flat_13: 0,
    char_14: 'soldier-11',
    app_rate_14: 1.22,
    avg_round_14: 2.63,
    app_flat_14: 0,
    char_15: 'Anton',
    app_rate_15: 1.1,
    avg_round_15: 2.88,
    app_flat_15: 0,
    char_16: 'Seth',
    app_rate_16: 0.81,
    avg_round_16: 2.79,
    app_flat_16: 0,
    char_17: 'Rina',
    app_rate_17: 0.69,
    avg_round_17: 2.82,
    app_flat_17: 0,
    char_18: 'Corin',
    app_rate_18: 0.49,
    avg_round_18: 2.18,
    app_flat_18: 0,
    char_19: 'Ben',
    app_rate_19: 0.37,
    avg_round_19: 2.67,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingyi',
    round: 2.74,
    char_1: 'nicole-demara',
    app_rate_1: 71.62,
    avg_round_1: 2.78,
    app_flat_1: 0,
    char_2: 'zhu-yuan',
    app_rate_2: 69.42,
    avg_round_2: 2.77,
    app_flat_2: 0,
    char_3: 'Ellen',
    app_rate_3: 10.6,
    avg_round_3: 2.6,
    app_flat_3: 0,
    char_4: 'Soukaku',
    app_rate_4: 9.57,
    avg_round_4: 2.61,
    app_flat_4: 0,
    char_5: 'Rina',
    app_rate_5: 6.91,
    avg_round_5: 2.66,
    app_flat_5: 0,
    char_6: 'Lucy',
    app_rate_6: 6.07,
    avg_round_6: 2.7,
    app_flat_6: 0,
    char_7: 'soldier-11',
    app_rate_7: 5.23,
    avg_round_7: 2.77,
    app_flat_7: 0,
    char_8: 'Anton',
    app_rate_8: 4.58,
    avg_round_8: 2.74,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 3.97,
    avg_round_9: 2.66,
    app_flat_9: 0,
    char_10: 'Nekomata',
    app_rate_10: 2.57,
    avg_round_10: 2.73,
    app_flat_10: 0,
    char_11: 'Corin',
    app_rate_11: 2.47,
    avg_round_11: 2.66,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 2.15,
    avg_round_12: 2.52,
    app_flat_12: 0,
    char_13: 'Seth',
    app_rate_13: 1.96,
    avg_round_13: 2.8,
    app_flat_13: 0,
    char_14: 'Piper',
    app_rate_14: 0.89,
    avg_round_14: 2.79,
    app_flat_14: 0,
    char_15: 'billy-kid',
    app_rate_15: 0.7,
    avg_round_15: 2.45,
    app_flat_15: 0,
    char_16: 'Ben',
    app_rate_16: 0.33,
    avg_round_16: 2.75,
    app_flat_16: 0,
    char_17: 'Koleda',
    app_rate_17: 0.09,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'Lycaon',
    app_rate_18: 0.09,
    avg_round_18: 1,
    app_flat_18: 0,
    char_19: 'anby-demara',
    app_rate_19: 0.09,
    avg_round_19: 2,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lucy',
    round: 2.74,
    char_1: 'soldier-11',
    app_rate_1: 37.61,
    avg_round_1: 2.73,
    app_flat_1: 0,
    char_2: 'jane-doe',
    app_rate_2: 29.17,
    avg_round_2: 2.82,
    app_flat_2: 0,
    char_3: 'Piper',
    app_rate_3: 29.0,
    avg_round_3: 2.68,
    app_flat_3: 0,
    char_4: 'Koleda',
    app_rate_4: 20.91,
    avg_round_4: 2.73,
    app_flat_4: 0,
    char_5: 'Seth',
    app_rate_5: 20.83,
    avg_round_5: 2.86,
    app_flat_5: 0,
    char_6: 'Qingyi',
    app_rate_6: 11.19,
    avg_round_6: 2.7,
    app_flat_6: 0,
    char_7: 'Nekomata',
    app_rate_7: 9.12,
    avg_round_7: 2.72,
    app_flat_7: 0,
    char_8: 'Ben',
    app_rate_8: 8.0,
    avg_round_8: 2.6,
    app_flat_8: 0,
    char_9: 'anby-demara',
    app_rate_9: 6.02,
    avg_round_9: 2.87,
    app_flat_9: 0,
    char_10: 'Ellen',
    app_rate_10: 4.99,
    avg_round_10: 2.7,
    app_flat_10: 0,
    char_11: 'Soukaku',
    app_rate_11: 4.04,
    avg_round_11: 2.77,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 3.79,
    avg_round_12: 2.72,
    app_flat_12: 0,
    char_13: 'nicole-demara',
    app_rate_13: 3.44,
    avg_round_13: 2.59,
    app_flat_13: 0,
    char_14: 'Corin',
    app_rate_14: 3.36,
    avg_round_14: 2.5,
    app_flat_14: 0,
    char_15: 'Lycaon',
    app_rate_15: 2.5,
    avg_round_15: 2.65,
    app_flat_15: 0,
    char_16: 'zhu-yuan',
    app_rate_16: 2.41,
    avg_round_16: 2.48,
    app_flat_16: 0,
    char_17: 'billy-kid',
    app_rate_17: 1.38,
    avg_round_17: 2.75,
    app_flat_17: 0,
    char_18: 'Anton',
    app_rate_18: 0.95,
    avg_round_18: 2.88,
    app_flat_18: 0,
    char_19: 'Rina',
    app_rate_19: 0.86,
    avg_round_19: 2.78,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Rina',
    round: 2.74,
    char_1: 'jane-doe',
    app_rate_1: 34.47,
    avg_round_1: 2.84,
    app_flat_1: 0,
    char_2: 'Ellen',
    app_rate_2: 30.21,
    avg_round_2: 2.67,
    app_flat_2: 0,
    char_3: 'Seth',
    app_rate_3: 23.55,
    avg_round_3: 2.86,
    app_flat_3: 0,
    char_4: 'Qingyi',
    app_rate_4: 20.99,
    avg_round_4: 2.66,
    app_flat_4: 0,
    char_5: 'grace-howard',
    app_rate_5: 17.16,
    avg_round_5: 2.78,
    app_flat_5: 0,
    char_6: 'Soukaku',
    app_rate_6: 15.18,
    avg_round_6: 2.78,
    app_flat_6: 0,
    char_7: 'Anton',
    app_rate_7: 12.91,
    avg_round_7: 2.61,
    app_flat_7: 0,
    char_8: 'Lycaon',
    app_rate_8: 11.06,
    avg_round_8: 2.76,
    app_flat_8: 0,
    char_9: 'anby-demara',
    app_rate_9: 7.94,
    avg_round_9: 2.4,
    app_flat_9: 0,
    char_10: 'Corin',
    app_rate_10: 7.09,
    avg_round_10: 2.74,
    app_flat_10: 0,
    char_11: 'Piper',
    app_rate_11: 4.82,
    avg_round_11: 2.74,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 4.11,
    avg_round_12: 2.84,
    app_flat_12: 0,
    char_13: 'Koleda',
    app_rate_13: 2.7,
    avg_round_13: 2.73,
    app_flat_13: 0,
    char_14: 'nicole-demara',
    app_rate_14: 2.41,
    avg_round_14: 2.82,
    app_flat_14: 0,
    char_15: 'Nekomata',
    app_rate_15: 1.42,
    avg_round_15: 2.78,
    app_flat_15: 0,
    char_16: 'Lucy',
    app_rate_16: 1.42,
    avg_round_16: 2.78,
    app_flat_16: 0,
    char_17: 'soldier-11',
    app_rate_17: 0.99,
    avg_round_17: 2.5,
    app_flat_17: 0,
    char_18: 'billy-kid',
    app_rate_18: 0.71,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'Ben',
    app_rate_19: 0.14,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jane-doe',
    round: 2.8,
    char_1: 'Seth',
    app_rate_1: 70.2,
    avg_round_1: 2.82,
    app_flat_1: 0,
    char_2: 'Lucy',
    app_rate_2: 31.56,
    avg_round_2: 2.82,
    app_flat_2: 0,
    char_3: 'Rina',
    app_rate_3: 22.63,
    avg_round_3: 2.84,
    app_flat_3: 0,
    char_4: 'grace-howard',
    app_rate_4: 18.81,
    avg_round_4: 2.81,
    app_flat_4: 0,
    char_5: 'Piper',
    app_rate_5: 10.43,
    avg_round_5: 2.78,
    app_flat_5: 0,
    char_6: 'Qingyi',
    app_rate_6: 7.91,
    avg_round_6: 2.66,
    app_flat_6: 0,
    char_7: 'anby-demara',
    app_rate_7: 7.91,
    avg_round_7: 2.75,
    app_flat_7: 0,
    char_8: 'Koleda',
    app_rate_8: 5.77,
    avg_round_8: 2.67,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 5.03,
    avg_round_9: 2.71,
    app_flat_9: 0,
    char_10: 'Nekomata',
    app_rate_10: 4.1,
    avg_round_10: 2.54,
    app_flat_10: 0,
    char_11: 'Soukaku',
    app_rate_11: 1.77,
    avg_round_11: 2.86,
    app_flat_11: 0,
    char_12: 'soldier-11',
    app_rate_12: 1.21,
    avg_round_12: 2.75,
    app_flat_12: 0,
    char_13: 'Lycaon',
    app_rate_13: 1.12,
    avg_round_13: 2.7,
    app_flat_13: 0,
    char_14: 'zhu-yuan',
    app_rate_14: 1.12,
    avg_round_14: 2.6,
    app_flat_14: 0,
    char_15: 'Corin',
    app_rate_15: 0.84,
    avg_round_15: 2.4,
    app_flat_15: 0,
    char_16: 'Ben',
    app_rate_16: 0.47,
    avg_round_16: 2.75,
    app_flat_16: 0,
    char_17: 'Anton',
    app_rate_17: 0.37,
    avg_round_17: 2.75,
    app_flat_17: 0,
    char_18: 'billy-kid',
    app_rate_18: 0.37,
    avg_round_18: 2.67,
    app_flat_18: 0,
    char_19: 'Ellen',
    app_rate_19: 0.28,
    avg_round_19: 2,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Seth',
    round: 2.81,
    char_1: 'jane-doe',
    app_rate_1: 97.04,
    avg_round_1: 2.82,
    app_flat_1: 0,
    char_2: 'Lucy',
    app_rate_2: 31.15,
    avg_round_2: 2.86,
    app_flat_2: 0,
    char_3: 'Rina',
    app_rate_3: 21.36,
    avg_round_3: 2.86,
    app_flat_3: 0,
    char_4: 'grace-howard',
    app_rate_4: 15.44,
    avg_round_4: 2.72,
    app_flat_4: 0,
    char_5: 'anby-demara',
    app_rate_5: 6.05,
    avg_round_5: 2.76,
    app_flat_5: 0,
    char_6: 'Qingyi',
    app_rate_6: 5.41,
    avg_round_6: 2.8,
    app_flat_6: 0,
    char_7: 'Piper',
    app_rate_7: 4.25,
    avg_round_7: 2.75,
    app_flat_7: 0,
    char_8: 'Koleda',
    app_rate_8: 3.47,
    avg_round_8: 2.7,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 2.57,
    avg_round_9: 2.79,
    app_flat_9: 0,
    char_10: 'Nekomata',
    app_rate_10: 2.32,
    avg_round_10: 2.43,
    app_flat_10: 0,
    char_11: 'Soukaku',
    app_rate_11: 1.8,
    avg_round_11: 2.91,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 0.77,
    avg_round_12: 2.5,
    app_flat_12: 0,
    char_13: 'Corin',
    app_rate_13: 0.77,
    avg_round_13: 2.25,
    app_flat_13: 0,
    char_14: 'Lycaon',
    app_rate_14: 0.51,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'Anton',
    app_rate_15: 0.26,
    avg_round_15: 2,
    app_flat_15: 0,
    char_16: 'Ellen',
    app_rate_16: 0.26,
    avg_round_16: 2.5,
    app_flat_16: 0,
    char_17: 'soldier-11',
    app_rate_17: 0.26,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'billy-kid',
    app_rate_18: 0.26,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'Ben',
    app_rate_19: 0.13,
    avg_round_19: 1,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
