import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { IZZZEngine } from '../../../common/model/graphql-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

import './zzz-card.scss';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  engine: IZZZEngine;
}

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

export const ZZZEngineCard: React.FC<IProps> = ({ engine }) => {
  const image = engine.image
    ? getImage(engine.image.localFile.childImageSharp)
    : null;
  return (
    <>
      <div className="zzz-engine">
        <div className="zzz-engine-header">
          <div className={`zzz-icon rarity-${engine.rarity}`}>
            {image ? (
              <GatsbyImage
                className="disable-transition"
                image={image}
                alt={`${engine.name}`}
              />
            ) : (
              <FontAwesomeIcon icon={faFloppyDisk} width="18" />
            )}
          </div>
          <div className="zzz-info">
            <div>
              <h5>
                {engine.name}{' '}
                {engine.name === 'test' && (
                  <span className="tag upcoming">Upcoming</span>
                )}
              </h5>
              <p>
                Rarity:{' '}
                <strong className={`rarity-${engine.rarity}`}>
                  {engine.rarity}
                </strong>{' '}
                | Type:{' '}
                <strong className={`type ${engine.type}`}>{engine.type}</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="zzz-engine-content">
          <div
            className={`description ${engine.element ? engine.element : ''}`}
          >
            {engine.description && (
              <div>{renderRichText(engine.description, options)}</div>
            )}
          </div>
        </div>
        {engine.stats.base_atk ? (
          <>
            <div className="stats">
              <div className="stat">
                <div>
                  <span className="name">Base ATK (Lv 60):</span>{' '}
                  <span className="value">{engine.stats.max_atk}</span>
                </div>
              </div>
              <div className="stat">
                <div>
                  <span className="name">{engine.stats.stat} (Lv 60):</span>{' '}
                  <span className="value">{engine.stats.max_special}</span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="stats-placeholder">
            Stats for this W-Engine aren't available yet.
          </div>
        )}
      </div>
    </>
  );
};
