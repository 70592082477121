/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';
import { ZZZCharStatsFullJSON } from '../data/zzz-char-stats-data-max';

interface IProps {
  name: string;
  slug?: string;
  limit?: number;
  element?: string;
  tableMode?: boolean;
}

export const ZZZStatsCalculator: React.FC<IProps> = ({
  element,
  tableMode,
  slug
}) => {
  const selectedChar = ZZZCharStatsFullJSON.find(
    (char) => char.character.toLowerCase() === slug
  );

  const popoverPEN = (
    <Popover id="popover-pen">
      <Popover.Header as="h3">PEN Ratio</Popover.Header>
      <Popover.Body>
        Ignores a percentage of the target’s DEF based on the PEN Ratio stat
        when attacking an enemy.
      </Popover.Body>
    </Popover>
  );

  const popoverPENFlat = (
    <Popover id="popover-pen">
      <Popover.Header as="h3">PEN</Popover.Header>
      <Popover.Body>
        Ignores a set amount of the target’s DEF based on the PEN stat when
        attacking an enemy.
      </Popover.Body>
    </Popover>
  );

  const popoverMAS = (
    <Popover id="popover-pen">
      <Popover.Header as="h3">Anomaly Mastery</Popover.Header>
      <Popover.Body>
        Affects the efficiency of Anomaly Buildup. Each point in Anomaly Mastery
        is equivalent to 1% of Anomaly Buildup Rate.
      </Popover.Body>
    </Popover>
  );

  const popoverProf = (
    <Popover id="popover-pen">
      <Popover.Header as="h3">Anomaly Proficiency</Popover.Header>
      <Popover.Body>
        Enhances the damage inflicted by Attribute Anomalies. Each point in
        Anomaly Proficiency is equivalent to 1% of Attribute Anomaly DMG.
      </Popover.Body>
    </Popover>
  );

  const popoverENE = (
    <Popover id="popover-pen">
      <Popover.Header as="h3">Energy Regen</Popover.Header>
      <Popover.Body>
        After entering combat, Agents passively generate Energy every second.
        The higher this value is, the more Energy gained per second.
      </Popover.Body>
    </Popover>
  );

  const popoverImpact = (
    <Popover id="popover-pen">
      <Popover.Header as="h3">Impact</Popover.Header>
      <Popover.Body>
        Attacking enemies increases their Daze. A higher Impact stat allows more
        Daze to accumulate with each attack.
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {!tableMode ? (
        <></>
      ) : (
        <Row xs={1} xl={1} xxl={2} className="info-list stats">
          <Col>
            <div className="stat-box">
              <div className="info-list-row">
                <div className="category">
                  <StaticImage
                    src="../../../../images/zzz/icons/stat_hp.png"
                    alt="Stat"
                  />
                  HP
                </div>
                <div className={`details  ${element}`}>{selectedChar.hp}</div>
              </div>
              <div className="info-list-row">
                <div className="category">
                  <StaticImage
                    src="../../../../images/zzz/icons/stat_def.png"
                    alt="Stat"
                  />
                  DEF
                </div>
                <div className={`details  ${element}`}>{selectedChar.def}</div>
              </div>
              <div className="info-list-row">
                <div className="category">
                  <StaticImage
                    src="../../../../images/zzz/icons/stat_atk.png"
                    alt="Stat"
                  />
                  ATK
                </div>
                <div className={`details  ${element}`}>{selectedChar.atk}</div>
              </div>
              <div className="info-list-row">
                <div className="category">
                  <StaticImage
                    src="../../../../images/zzz/icons/stat_crit.png"
                    alt="Stat"
                  />
                  CRIT Rate
                </div>
                <div className={`details  ${element} `}>
                  {selectedChar.critRate}%
                </div>
              </div>
              <div className="info-list-row">
                <div className="category">
                  <StaticImage
                    src="../../../../images/zzz/icons/stat_critdmg.png"
                    alt="Stat"
                  />
                  CRIT DMG
                </div>
                <div className={`details  ${element}`}>
                  {selectedChar.critDmg}%
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="stat-box">
              <div className="info-list-row">
                <div className="category with-help">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="auto-end"
                    overlay={popoverPEN}
                  >
                    <span>
                      <StaticImage
                        src="../../../../images/zzz/icons/stat_pen.png"
                        alt="Stat"
                      />
                      PEN Ratio{' '}
                      <FontAwesomeIcon icon={faCircleQuestion} width="18" />{' '}
                    </span>
                  </OverlayTrigger>
                </div>
                <div className={`details  ${element}`}>
                  {selectedChar.penRatio}%
                </div>
              </div>
              <div className="info-list-row">
                <div className="category with-help">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="auto-end"
                    overlay={popoverImpact}
                  >
                    <span>
                      <StaticImage
                        src="../../../../images/zzz/icons/stat_impact.png"
                        alt="Stat"
                      />
                      Impact{' '}
                      <FontAwesomeIcon icon={faCircleQuestion} width="18" />{' '}
                    </span>
                  </OverlayTrigger>
                </div>
                <div className={`details  ${element}`}>
                  {selectedChar.impact}
                </div>
              </div>
              <div className="info-list-row">
                <div className="category with-help">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="auto-end"
                    overlay={popoverMAS}
                  >
                    <span>
                      <StaticImage
                        src="../../../../images/zzz/icons/stat_mastery.png"
                        alt="Stat"
                      />
                      Anomaly Mastery{' '}
                      <FontAwesomeIcon icon={faCircleQuestion} width="18" />{' '}
                    </span>
                  </OverlayTrigger>
                </div>
                <div className={`details  ${element}`}>
                  {selectedChar.ano_mastery}
                </div>
              </div>
              <div className="info-list-row">
                <div className="category with-help">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="auto-end"
                    overlay={popoverProf}
                  >
                    <span>
                      Anomaly Proficiency{' '}
                      <FontAwesomeIcon icon={faCircleQuestion} width="18" />{' '}
                    </span>
                  </OverlayTrigger>
                </div>
                <div className={`details`}>{selectedChar.ano_proficiency}</div>
              </div>
              <div className="info-list-row">
                <div className="category with-help">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="auto-end"
                    overlay={popoverENE}
                  >
                    <span>
                      <StaticImage
                        src="../../../../images/zzz/icons/stat_energy.png"
                        alt="Stat"
                      />
                      Energy Regen{' '}
                      <FontAwesomeIcon icon={faCircleQuestion} width="18" />{' '}
                    </span>
                  </OverlayTrigger>
                </div>
                <div className={`details `}>{selectedChar.energyRegen}</div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
