export const ZZZDriveSetsJSON = [
  {
    name: 'Woodpecker Electro',
    tier: '2',
    piece_2: 'CRIT Rate +<b>8%</b>.',
    piece_4:
      'Triggering a critical hit with a Basic Attack, Dodge Counter or EX Special Attack increases the equippers ATK by <b>9%</b> for <b>6s</b>. The buff duration for different skills are calculated separately.'
  },
  {
    name: 'Puffer Electro',
    tier: '2',
    piece_2: 'PEN Ratio +<b>8%</b>.',
    piece_4:
      'Ultimate DMG increases by <b>20%</b>. Launching an Ultimate increases the equippers ATK by <b>15%</b> for <b>12s</b>.'
  },
  {
    name: 'Shockstar Disco',
    tier: '2',
    piece_2: 'Impact +<b>6%</b>.',
    piece_4:
      'Basic Attacks, Dash Attacks, and Dodge Counters, inflict <b>20%</b> more <b>Daze</b> upon the main target.'
  },
  {
    name: 'Freedom Blues',
    tier: '2',
    piece_2: 'Anomaly Proficiency +<b>30</b>.',
    piece_4:
      'When an EX Special Attack hits an enemy, reduce the targets Anomaly Buildup RES to equippers attribute by <b>20%</b> for <b>8s</b>. This effect does not stack with others of the same attribute.'
  },
  {
    name: 'Hormone Punk',
    tier: '2',
    piece_2: 'ATK +<b>10%</b>.',
    piece_4:
      "Upon entering combat or switching in, its user's ATK increased by <b>25%</b> for <b>10s</b>. This effect can be triggered once every <b>20s</b>."
  },
  {
    name: 'Soul Rock',
    tier: '2',
    piece_2: 'DEF +<b>16%</b>.',
    piece_4:
      'Upon receiving an enemy attack and losing HP, the equipper takes <b>40%</b> less DMG for <b>2.5s</b>. This effect can trigger once every <b>15s</b>.'
  },
  {
    name: 'Inferno Metal',
    tier: '2',
    element: 'Fire',
    piece_2: '<u>Fire DMG</u> +<b>10%</b>.',
    piece_4:
      "Upon hitting a <u>Burning</u> enemy, the equipper's CRIT Rate is increased by <b>28%</b> for <b>8s</b>."
  },
  {
    name: 'Chaotic Metal',
    tier: '2',
    element: 'Ether',
    piece_2: '<u>Ether DMG</u> +<b>10%</b>.',
    piece_4:
      "The equipper's CRIT DMG increases by <b>20%</b>. When any character in the squad triggers <u>Corruption's</u> additional DMG, this effect further increases by <b>5.5%</b> for <b>8s</b>, stacking up to <b>6</b> times. Repeated triggers reset the duration."
  },
  {
    name: 'Thunder Metal',
    tier: '2',
    element: 'Electric',
    piece_2: '<u>Electric DMG</u> +<b>10%</b>.',
    piece_4:
      "As long as an enemy in combat is <u>Shocked</u>, the equipper's ATK is increased by <b>28%</b>."
  },
  {
    name: 'Polar Metal',
    tier: '2',
    element: 'Ice',
    piece_2: '<u>Ice DMG</u> +<b>10%</b>.',
    piece_4:
      'Increases the DMG of Basic Attack and Dash Attack by <b>20%</b>. When any squad member inflicts <u>Freeze</u> or <u>Shatter</u>, this effect increases by an additional <b>20%</b> for <b>12s</b>.'
  },
  {
    name: 'Fanged Metal',
    tier: '2',
    element: 'Physical',
    piece_2: '<u>Physical DMG</u> +<b>10%</b>.',
    piece_4:
      'Whenever a squad member inflicts <u>Assault</u> on an enemy, the equipper deals <b>35%</b> increased damage to the target for <b>12s</b>.'
  },
  {
    name: 'Swing Jazz',
    tier: '2',
    element: 'Physical',
    piece_2: 'Energy Regen +<b>20%</b>.',
    piece_4:
      "Launching a Chain Attack or Ultimate increases all squad members' DMG by <b>15%</b> for <b>12s</b>. Passive effects of the same name do not stack."
  },
  {
    name: 'Proto Punk',
    tier: '2',
    piece_2: 'Shield Effect +<b>15%</b>.',
    piece_4:
      'When any squad member triggers a Defensive Assist or Evasive Assist, all squad members deal <b>15%</b> increased DMG, lasting <b>10s</b>. Passive effects of the same name do not stack.'
  },
  {
    name: 'Chaos Jazz',
    tier: '2',
    piece_2: 'Anomaly Proficiency +<b>30</b>.',
    piece_4:
      '<strong class="Fire">Fire DMG</strong> and <strong class="Electric">Electric DMG</strong> are increased by <b>15%</b>. When off-field, the damage caused by EX Special Attacks and Assist Attacks is increased by <b>20%</b>. When the character switches back onto the field, this buff continues for <b>5s</b>. The lasting effect can be triggered once every <b>7.5s</b>.'
  }
];
