/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ZZZCharacter } from '../components/zzz-character';

interface IProps {
  name: string;
  character_element?: string;
}

export const ZZZProfileTeamCalcs: React.FC<IProps> = ({ name }) => {
  return (
    <>
      {name === 'jane-doe' && (
        <>
          <p>
            The following calculations display the impact of support characters
            buffs on Janes burst damage rotation. For this reason do not treat
            these calculations as a full representation of a teams performance -
            they do not include full team damage, rotations or W-Engine buffs.
            Also of note is that Support type characters W-Engines are more
            beneficial than Defensive characters giving Rina an additional
            advantage not listed here in fully geared scenario.
          </p>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>M0 Jane Doe damage output including team buffs</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Jane + Qingyi (Max Stacks)</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">213,907 DPS</span>
                          <span className="percent">148.4%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Jane + Seth + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '95.63%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">204,556 DPS</span>
                          <span className="percent">141.91%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Jane + Rina + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '90.24%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">193,030 DPS</span>
                          <span className="percent">133.92%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Solo</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '67.38%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">144,141 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'ellen' && (
        <>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>M0 Ellen damage output including team buffs</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Lycaon + Soukaku M4 + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">217,315 DPS</span>
                          <span className="percent">245.36%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Qingyi (20 Stacks) + Soukaku M6 + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '98.57%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">214,212 DPS</span>
                          <span className="percent">241.86%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Lycaon + Soukaku + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '92.59%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">201,218 DPS</span>
                          <span className="percent">227.18%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Qingyi (20 Stacks) + Soukaku + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '89.61%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">194,738 DPS</span>
                          <span className="percent">219.87%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Lycaon + Rina + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '79.42%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">172,599 DPS</span>
                          <span className="percent">194.87%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Solo</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '40.76%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">88,570 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'soldier-11' && (
        <>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>M0 Soldier 11 damage output including team buffs</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Koleda + Lucy M4 + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">109,453 DPS</span>
                          <span className="percent">134.19%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Koleda + Lucy + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '96.72%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">105,865 DPS</span>
                          <span className="percent">129.79%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Koleda + Ben + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '91.35%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">99,986 DPS</span>
                          <span className="percent">122.59%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Solo</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '74.52%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">81,564 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'corin' && (
        <>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>M0 Corin damage output including team buffs</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Lycaon + Soukaku M4 + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">160,101 DPS</span>
                          <span className="percent">173.8%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Lycaon + Soukaku + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '93.49%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">149,679 DPS</span>
                          <span className="percent">162.49%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Lycaon + Rina + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '90.02%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">144,124 DPS</span>
                          <span className="percent">156.46%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Solo</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '57.54%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">92,118 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'zhu-yuan' && (
        <>
          <Row xs={1} xl={1} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>M0 Zhu Yuan damage output including team buffs</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Qingyi (20 Stacks) + Nicole M6 + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">257,656 DPS</span>
                          <span className="percent">208.7%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Qingyi (20 Stacks) + Nicole + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '94.01%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">242,211 DPS</span>
                          <span className="percent">196.19%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Anby + Nicole M6 + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '67.33%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">173,478 DPS</span>
                          <span className="percent">140.51%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Anby + Nicole + Swing Jazz</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '63.29%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">163,083 DPS</span>
                          <span className="percent">132.09%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Solo</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '47.92%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">123,459 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {name === 'qingyi' && (
        <>
          <Row xs={1} xl={2} className="dps-comparison">
            <Col>
              <div className="box">
                <h5>M0 Qingyi Voltage (Daze)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Moonlit Blossoms 100 Voltage</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">842 DPS</span>
                          <span className="percent">108.93%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Moonlit Blossoms 75 Voltage</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '91.81%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">773 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="box">
                <h5>M0 Qingyi Charge vs Uncharged Special (Daze)</h5>
                <div className={`simulation-container`}>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Charged-EX Special</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '100%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">1,114 DPS</span>
                          <span className="percent">112.97%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-row">
                    <div className="character">
                      <ZZZCharacter slug={name} mode="icon" enablePopover />
                    </div>
                    <div className="chart">
                      <div className="info-2">
                        <p>Uncharged-EX Special</p>
                      </div>
                      <div className="dps-bar-outside">
                        <div
                          className={`dps-bar top-1`}
                          style={{ width: '88.51%' }}
                        ></div>
                        <div className="data">
                          <span className="dps">986 DPS</span>
                          <span className="percent">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
