/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ZZZDriveSetsJSON } from '../data/zzz-drives-data';
import './zzz-card.scss';
import { ZZZDiskImage } from './zzz-disk-image';
import { Accordion } from 'react-bootstrap';

interface IProps {
  name: string;
  setup?: string;
  onProfile?: boolean;
}

export const ZZZDiskSet: React.FC<IProps> = ({ name, setup, onProfile }) => {
  return (
    <>
      {ZZZDriveSetsJSON.map((disk) => {
        return (
          <>
            {disk.name === name && (
              <>
                {onProfile ? (
                  <Accordion className="zzz-weapon-accordion">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div
                          className={`zzz-icon ${
                            disk.tier === '1' ? 'rarity-A' : 'rarity-S'
                          }`}
                        >
                          <ZZZDiskImage name={disk.name} />
                        </div>
                        <span
                          className={`zzz-weapon-name  ${
                            disk.tier === '1' ? 'rarity-A' : 'rarity-S'
                          }`}
                        >
                          {disk.name} <span>({setup}-PC)</span>
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="zzz-set-content">
                          <div
                            className={`zzz-disk-description ${
                              disk.element && disk.element
                            }`}
                          >
                            <>
                              <div>
                                <span className="set">(2)</span>
                                <div
                                  className={`description`}
                                  dangerouslySetInnerHTML={{
                                    __html: disk.piece_2
                                  }}
                                />
                              </div>
                              <div>
                                <span className="set">(4)</span>
                                <div
                                  className={`description`}
                                  dangerouslySetInnerHTML={{
                                    __html: disk.piece_4
                                  }}
                                />
                              </div>
                            </>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ) : (
                  <>
                    <div className="zzz-disk-set">
                      <div className="zzz-disk-header">
                        <div
                          className={`zzz-icon ${
                            disk.tier === '1' ? 'rarity-A' : 'rarity-S'
                          }`}
                        >
                          <ZZZDiskImage name={disk.name} />
                        </div>
                        <div className="zzz-info">
                          <h5>{disk.name}</h5>
                        </div>
                      </div>
                      <div
                        className={`zzz-disk-content ${
                          disk.element && disk.element
                        }`}
                      >
                        <div>
                          <span className="set">(2)</span>
                          <div
                            className={`description`}
                            dangerouslySetInnerHTML={{
                              __html: disk.piece_2
                            }}
                          />
                        </div>
                        <div>
                          <span className="set">(4)</span>
                          <div
                            className={`description`}
                            dangerouslySetInnerHTML={{
                              __html: disk.piece_4
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        );
      })}
    </>
  );
};
