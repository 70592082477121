export const ZZZCharactersDaze = [
  {
    id: 0,
    name: 'Lycaon',
    slug: 'lycaon',
    disclaimer: '',
    // Damage
    dps: {
      damage: 14103,
      damage_e1: 14103,
      damage_e2: 14103,
      damage_e3: 15356,
      damage_e4: 15356,
      damage_e5: 16609,
      damage_e6: 22168
    },
    dmg: {
      damage: 228441,
      damage_e1: 228441,
      damage_e2: 228441,
      damage_e3: 248739,
      damage_e4: 248739,
      damage_e5: 269037,
      damage_e6: 359072
    },
    // Daze
    dps_standard: {
      damage: 704,
      damage_e1: 755,
      damage_e2: 755,
      damage_e3: 802,
      damage_e4: 802,
      damage_e5: 850,
      damage_e6: 850
    },
    dmg_standard: {
      damage: 11399,
      damage_e1: 12227,
      damage_e2: 12227,
      damage_e3: 12995,
      damage_e4: 12995,
      damage_e5: 13763,
      damage_e6: 13763
    },
    time: 16.2,
    disk_6: 'Impact%',
    disk_5: 'Ice DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Shockstar Disco',
    set_2: 'Swing Jazz',
    weapon: 'The Restrained',
    weapon_S: '1'
  },
  {
    id: 1,
    name: 'Anby',
    slug: 'anby-demara',
    disclaimer: '',
    // Damage
    dps: {
      damage: 11492,
      damage_e1: 11492,
      damage_e2: 11492,
      damage_e3: 12435,
      damage_e4: 12435,
      damage_e5: 13377,
      damage_e6: 15209
    },
    dmg: {
      damage: 164532,
      damage_e1: 164532,
      damage_e2: 164532,
      damage_e3: 178025,
      damage_e4: 178025,
      damage_e5: 191519,
      damage_e6: 217754
    },
    // Daze
    dps_standard: {
      damage: 724,
      damage_e1: 724,
      damage_e2: 743,
      damage_e3: 789,
      damage_e4: 789,
      damage_e5: 836,
      damage_e6: 836
    },
    dmg_standard: {
      damage: 10361,
      damage_e1: 10361,
      damage_e2: 10631,
      damage_e3: 11299,
      damage_e4: 11299,
      damage_e5: 11967,
      damage_e6: 11967
    },
    time: 14.32,
    disk_6: 'Impact%',
    disk_5: 'Electric DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Shockstar Disco',
    set_2: 'Swing Jazz',
    weapon: 'The Restrained',
    weapon_S: '1'
  },
  {
    id: 2,
    name: 'Koleda',
    slug: 'koleda',
    disclaimer: '',
    // Damage
    dps: {
      damage: 10716,
      damage_e1: 10716,
      damage_e2: 10716,
      damage_e3: 11585,
      damage_e4: 11585,
      damage_e5: 12455,
      damage_e6: 13315
    },
    dmg: {
      damage: 192019,
      damage_e1: 192019,
      damage_e2: 192019,
      damage_e3: 207598,
      damage_e4: 207598,
      damage_e5: 223178,
      damage_e6: 238586
    },
    // Daze
    dps_standard: {
      damage: 867,
      damage_e1: 871,
      damage_e2: 871,
      damage_e3: 926,
      damage_e4: 926,
      damage_e5: 980,
      damage_e6: 980
    },
    dmg_standard: {
      damage: 15538,
      damage_e1: 15610,
      damage_e2: 15610,
      damage_e3: 16589,
      damage_e4: 16589,
      damage_e5: 17568,
      damage_e6: 17568
    },
    time: 17.92,
    disk_6: 'Impact%',
    disk_5: 'Fire DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Shockstar Disco',
    set_2: 'Swing Jazz',
    weapon: 'Hellfire Gears',
    weapon_S: '1'
  },
  {
    id: 3,
    name: 'Qingyi',
    slug: 'qingyi',
    disclaimer: '',
    // Damage
    dps: {
      damage: 19337,
      damage_e1: 23792,
      damage_e2: 23792,
      damage_e3: 25790,
      damage_e4: 25790,
      damage_e5: 27788,
      damage_e6: 38310
    },
    dmg: {
      damage: 344815,
      damage_e1: 424258,
      damage_e2: 424258,
      damage_e3: 459883,
      damage_e4: 459883,
      damage_e5: 495508,
      damage_e6: 683153
    },
    // Daze
    dps_standard: {
      damage: 786,
      damage_e1: 795,
      damage_e2: 843,
      damage_e3: 895,
      damage_e4: 895,
      damage_e5: 946,
      damage_e6: 946
    },
    dmg_standard: {
      damage: 14013,
      damage_e1: 14179,
      damage_e2: 15031,
      damage_e3: 15952,
      damage_e4: 15952,
      damage_e5: 16873,
      damage_e6: 16873
    },
    time: 17.83,
    disk_6: 'Impact%',
    disk_5: 'Electric DMG',
    disk_4: 'CRIT Rate',
    substats: 'ATK (18%), CRIT Rate (33.6%), CRIT DMG (67.2%)',
    set_1: 'Shockstar Disco',
    set_2: 'Swing Jazz',
    weapon: 'Ice-Jade Teapot',
    weapon_S: '1'
  }
];
