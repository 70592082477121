import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

interface IProps {
  stat: string;
}

export const ZZZStat: React.FC<IProps> = ({ stat }) => {
  return (
    <div className="zzz-stat">
      {stat === 'HP' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_hp.png"
            alt="HP"
          />
          <span>HP</span>
        </>
      )}
      {stat === 'HP%' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_hp.png"
            alt="HP"
          />
          <span>HP%</span>
        </>
      )}
      {stat === 'DEF' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_def.png"
            alt="DEF"
          />
          <span>DEF</span>
        </>
      )}
      {stat === 'DEF%' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_def.png"
            alt="DEF"
          />
          <span>DEF%</span>
        </>
      )}
      {stat === 'ATK' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_atk.png"
            alt="ATK"
          />
          <span>ATK</span>
        </>
      )}
      {stat === 'ATK%' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_atk.png"
            alt="ATK%"
          />
          <span>ATK%</span>
        </>
      )}
      {stat === 'CRIT Rate' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_crit.png"
            alt="CRIT Rate"
          />
          <span>CRIT Rate%</span>
        </>
      )}
      {stat === 'CRIT DMG' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_critdmg.png"
            alt="CRIT DMG"
          />
          <span>CRIT DMG%</span>
        </>
      )}
      {stat === 'Impact' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_impact.png"
            alt="Impact"
          />
          <span>Impact</span>
        </>
      )}
      {stat === 'Impact%' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_impact.png"
            alt="Impact"
          />
          <span>Impact%</span>
        </>
      )}
      {stat === 'Anomaly Mastery' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_mastery.png"
            alt="Anomaly Mastery"
          />
          <span>Anomaly Mastery</span>
        </>
      )}
      {stat === 'PEN Ratio' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_pen.png"
            alt="PEN Ratio"
          />
          <span>PEN Ratio%</span>
        </>
      )}
      {stat === 'PEN' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_penflat.png"
            alt="PEN"
          />
          <span>PEN</span>
        </>
      )}
      {stat === 'Energy Regen' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_energy.png"
            alt="Energy Regen"
          />
          <span>Energy Regen</span>
        </>
      )}
      {stat === 'Energy Regen%' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_energy.png"
            alt="Energy Regen"
          />
          <span>Energy Regen%</span>
        </>
      )}
      {stat === 'Anomaly Proficiency' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/stat_anomaly.png"
            alt="Anomaly Proficiency"
          />
          <span>Anomaly Proficiency</span>
        </>
      )}
      {stat === 'Ether DMG' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/ele_ether.png"
            alt="Ether DMG"
          />
          <span>Ether DMG%</span>
        </>
      )}
      {stat === 'Electric DMG' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/ele_electric.png"
            alt="Electric DMG"
          />
          <span>Electric DMG%</span>
        </>
      )}
      {stat === 'Physical DMG' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/ele_physical.png"
            alt="Physical DMG"
          />
          <span>Physical DMG%</span>
        </>
      )}
      {stat === 'Ice DMG' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/ele_ice.png"
            alt="Ice DMG"
          />
          <span>Ice DMG%</span>
        </>
      )}
      {stat === 'Fire DMG' && (
        <>
          <StaticImage
            src="../../../../images/zzz/icons/ele_fire.png"
            alt="Fire DMG"
          />
          <span>Fire DMG%</span>
        </>
      )}
      {stat === 'Flex' && (
        <>
          <span>Flex</span>
        </>
      )}
    </div>
  );
};
