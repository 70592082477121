import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

interface IProps {
  name: string;
}

export const ZZZDiskImage: React.FC<IProps> = ({ name }) => {
  return (
    <>
      {name === 'Woodpecker Electro' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_10.webp"
          alt={name}
        />
      )}
      {name === 'Puffer Electro' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_11.webp"
          alt={name}
        />
      )}
      {name === 'Shockstar Disco' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_12.webp"
          alt={name}
        />
      )}
      {name === 'Freedom Blues' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_13.webp"
          alt={name}
        />
      )}
      {name === 'Hormone Punk' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_14.webp"
          alt={name}
        />
      )}
      {name === 'Soul Rock' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_15.webp"
          alt={name}
        />
      )}
      {name === 'Inferno Metal' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_16.webp"
          alt={name}
        />
      )}
      {name === 'Chaotic Metal' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_17.webp"
          alt={name}
        />
      )}
      {name === 'Thunder Metal' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_18.webp"
          alt={name}
        />
      )}
      {name === 'Polar Metal' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_19.webp"
          alt={name}
        />
      )}
      {name === 'Fanged Metal' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_20.webp"
          alt={name}
        />
      )}
      {name === 'Swing Jazz' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_21.webp"
          alt={name}
        />
      )}
      {name === 'Proto Punk' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_22.webp"
          alt={name}
        />
      )}
      {name === 'Chaos Jazz' && (
        <StaticImage
          src="../../../../images/zzz/drives/set_23.webp"
          alt={name}
        />
      )}
    </>
  );
};
