export const ZZZBuilds = [
  {
    char: 'Seth',
    app_rate: 22.14,
    avg_round: 2.81,
    std_dev_round: 0.46,
    role: 'Support',
    rarity: 'A',
    diff: 0.0,
    diff_rounds: 1,
    weapon_1: 'Peacekeeper - Specialized',
    weapon_1_app: 80.25,
    weapon_1_round: 2.82,
    weapon_2: 'Bunny Band',
    weapon_2_app: 5.98,
    weapon_2_round: 2.68,
    weapon_3: 'Spring Embrace',
    weapon_3_app: 4.17,
    weapon_3_round: 2.86,
    weapon_4: 'Original Transmorpher',
    weapon_4_app: 3.06,
    weapon_4_round: 2.84,
    weapon_5: 'Starlight Engine',
    weapon_5_app: 1.39,
    weapon_5_round: 2.7,
    weapon_6: 'Big Cylinder',
    weapon_6_app: 0.83,
    weapon_6_round: 2.5,
    weapon_7: 'Street Superstar',
    weapon_7_app: 0.56,
    weapon_7_round: 3.0,
    weapon_8: 'Demara Battery Mark II',
    weapon_8_app: 0.42,
    weapon_8_round: 3.0,
    weapon_9: 'The Brimstone',
    weapon_9_app: 0.42,
    weapon_9_round: 3.0,
    weapon_10: 'Starlight Engine Replica',
    weapon_10_app: 0.28,
    weapon_10_round: 3.0,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 24.34,
    artifact_1_round: 2.75,
    artifact_2: 'Swing Jazz, Freedom Blues',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 10.85,
    artifact_2_round: 2.86,
    artifact_3: 'Freedom Blues, Swing Jazz',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 10.71,
    artifact_3_round: 2.84,
    artifact_4: 'Freedom Blues, Hormone Punk',
    artifact_4_1: 'Freedom Blues',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 5.84,
    artifact_4_round: 2.74,
    artifact_5: 'Swing Jazz, Flex',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 4.03,
    artifact_5_round: 2.81,
    artifact_6: 'Swing Jazz, Shockstar Disco',
    artifact_6_1: 'Swing Jazz',
    artifact_6_2: 'Shockstar Disco',
    artifact_6_3: '',
    artifact_6_app: 3.89,
    artifact_6_round: 2.9,
    artifact_7: 'Hormone Punk, Swing Jazz',
    artifact_7_1: 'Hormone Punk',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 3.62,
    artifact_7_round: 2.88,
    artifact_8: 'Freedom Blues, Flex',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Flex',
    artifact_8_3: '',
    artifact_8_app: 1.95,
    artifact_8_round: 2.46,
    artifact_9: 'Freedom Blues, Shockstar Disco',
    artifact_9_1: 'Freedom Blues',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 1.95,
    artifact_9_round: 2.67,
    artifact_10: 'Hormone Punk, Swing Jazz, Flex',
    artifact_10_1: 'Hormone Punk',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: 'Flex',
    artifact_10_app: 1.95,
    artifact_10_round: 2.64,
    app_0: 9.32,
    round_0: 2.65,
    app_1: 13.77,
    round_1: 2.77,
    app_2: 16.69,
    round_2: 2.88,
    app_3: 11.4,
    round_3: 2.89,
    app_4: 11.68,
    round_4: 2.82,
    app_5: 7.37,
    round_5: 2.76,
    app_6: 29.76,
    round_6: 2.82,
    cons_avg: 3.44,
    sample: 777,
    sample_app_flat: 617
  },
  {
    char: 'jane-doe',
    app_rate: 30.61,
    avg_round: 2.8,
    std_dev_round: 0.48,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: 0.0,
    diff_rounds: 1,
    weapon_1: 'Sharpened Stinger',
    weapon_1_app: 60.95,
    weapon_1_round: 2.85,
    weapon_2: 'Rainforest Gourmet',
    weapon_2_app: 11.72,
    weapon_2_round: 2.72,
    weapon_3: 'Weeping Gemini',
    weapon_3_app: 10.95,
    weapon_3_round: 2.68,
    weapon_4: 'Electro-Lip Gloss',
    weapon_4_app: 10.56,
    weapon_4_round: 2.86,
    weapon_5: 'Fusion Compiler',
    weapon_5_app: 4.94,
    weapon_5_round: 2.65,
    weapon_6: 'Roaring Ride',
    weapon_6_app: 0.78,
    weapon_6_round: 2.75,
    weapon_7: '[Magnetic Storm] Bravo',
    weapon_7_app: 0.1,
    weapon_7_round: 3.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Fanged Metal, Freedom Blues',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 85.85,
    artifact_1_round: 2.81,
    artifact_2: 'Freedom Blues, Fanged Metal',
    artifact_2_1: 'Freedom Blues',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 4.36,
    artifact_2_round: 2.78,
    artifact_3: 'Fanged Metal, Hormone Punk',
    artifact_3_1: 'Fanged Metal',
    artifact_3_2: 'Hormone Punk',
    artifact_3_3: '',
    artifact_3_app: 3.29,
    artifact_3_round: 2.81,
    artifact_4: 'Fanged Metal, Puffer Electro',
    artifact_4_1: 'Fanged Metal',
    artifact_4_2: 'Puffer Electro',
    artifact_4_3: '',
    artifact_4_app: 1.65,
    artifact_4_round: 3.0,
    artifact_5: 'Fanged Metal, Flex',
    artifact_5_1: 'Fanged Metal',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 0.78,
    artifact_5_round: 2.5,
    artifact_6: 'Freedom Blues, Swing Jazz',
    artifact_6_1: 'Freedom Blues',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 0.58,
    artifact_6_round: 2.6,
    artifact_7: 'Freedom Blues, Flex',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Flex',
    artifact_7_3: '',
    artifact_7_app: 0.48,
    artifact_7_round: 3.0,
    artifact_8: 'Freedom Blues, Woodpecker Electro',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Woodpecker Electro',
    artifact_8_3: '',
    artifact_8_app: 0.39,
    artifact_8_round: 2.25,
    artifact_9: 'Freedom Blues, Hormone Punk',
    artifact_9_1: 'Freedom Blues',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 0.39,
    artifact_9_round: 3.0,
    artifact_10: 'Fanged Metal, Swing Jazz',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: '',
    artifact_10_app: 0.39,
    artifact_10_round: 3.0,
    app_0: 79.26,
    round_0: 2.8,
    app_1: 8.91,
    round_1: 2.9,
    app_2: 9.21,
    round_2: 2.93,
    app_3: 0.48,
    round_3: 3.0,
    app_4: 0.1,
    round_4: 3.0,
    app_5: 0.1,
    round_5: 3.0,
    app_6: 1.94,
    round_6: 3.0,
    cons_avg: 0.41,
    sample: 1074,
    sample_app_flat: 856
  },
  {
    char: 'nicole-demara',
    app_rate: 70.25,
    avg_round: 2.74,
    std_dev_round: 0.53,
    role: 'Support',
    rarity: 'A',
    diff: -11.46,
    diff_rounds: 0.2,
    weapon_1: 'The Vault',
    weapon_1_app: 88.4,
    weapon_1_round: 2.76,
    weapon_2: 'Weeping Cradle',
    weapon_2_app: 4.89,
    weapon_2_round: 2.74,
    weapon_3: 'Slice of Time',
    weapon_3_app: 2.25,
    weapon_3_round: 2.64,
    weapon_4: 'Kaboom the Cannon',
    weapon_4_app: 1.74,
    weapon_4_round: 2.7,
    weapon_5: '[Reverb] Mark II',
    weapon_5_app: 1.27,
    weapon_5_round: 2.25,
    weapon_6: 'Unfettered Game Ball',
    weapon_6_app: 0.55,
    weapon_6_round: 2.83,
    weapon_7: '[Reverb] Mark III',
    weapon_7_app: 0.34,
    weapon_7_round: 2.88,
    weapon_8: '[Reverb] Mark I',
    weapon_8_app: 0.13,
    weapon_8_round: 2.33,
    weapon_9: 'Bashful Demon',
    weapon_9_app: 0.04,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Swing Jazz, Chaotic Metal',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Chaotic Metal',
    artifact_1_3: '',
    artifact_1_app: 35.22,
    artifact_1_round: 2.73,
    artifact_2: 'Swing Jazz, Freedom Blues',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 29.06,
    artifact_2_round: 2.79,
    artifact_3: 'Swing Jazz, Flex',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Flex',
    artifact_3_3: '',
    artifact_3_app: 6.67,
    artifact_3_round: 2.82,
    artifact_4: 'Swing Jazz, Woodpecker Electro',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Woodpecker Electro',
    artifact_4_3: '',
    artifact_4_app: 5.69,
    artifact_4_round: 2.84,
    artifact_5: 'Freedom Blues, Swing Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: '',
    artifact_5_app: 4.42,
    artifact_5_round: 2.7,
    artifact_6: 'Chaotic Metal, Swing Jazz',
    artifact_6_1: 'Chaotic Metal',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 3.1,
    artifact_6_round: 2.31,
    artifact_7: 'Swing Jazz, Hormone Punk',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 2.29,
    artifact_7_round: 2.76,
    artifact_8: 'Swing Jazz, Shockstar Disco',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 2.12,
    artifact_8_round: 2.8,
    artifact_9: 'Swing Jazz, Puffer Electro',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 1.44,
    artifact_9_round: 2.65,
    artifact_10: 'Freedom Blues, Chaotic Metal',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Chaotic Metal',
    artifact_10_3: '',
    artifact_10_app: 0.93,
    artifact_10_round: 2.65,
    app_0: 0.42,
    round_0: 2.3,
    app_1: 2.0,
    round_1: 2.59,
    app_2: 4.76,
    round_2: 2.68,
    app_3: 6.16,
    round_3: 2.63,
    app_4: 8.28,
    round_4: 2.66,
    app_5: 8.62,
    round_5: 2.69,
    app_6: 69.75,
    round_6: 2.79,
    cons_avg: 5.25,
    sample: 2465,
    sample_app_flat: 1893
  },
  {
    char: 'zhu-yuan',
    app_rate: 62.13,
    avg_round: 2.74,
    std_dev_round: 0.53,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: -11.73,
    diff_rounds: 0.15,
    weapon_1: 'Riot Suppressor Mark VI',
    weapon_1_app: 46.65,
    weapon_1_round: 2.83,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 31.63,
    weapon_2_round: 2.71,
    weapon_3: 'The Brimstone',
    weapon_3_app: 9.91,
    weapon_3_round: 2.82,
    weapon_4: 'Cannon Rotor',
    weapon_4_app: 5.81,
    weapon_4_round: 2.62,
    weapon_5: 'Gilded Blossom',
    weapon_5_app: 2.93,
    weapon_5_round: 2.4,
    weapon_6: 'Steel Cushion',
    weapon_6_app: 1.44,
    weapon_6_round: 2.71,
    weapon_7: 'Street Superstar',
    weapon_7_app: 1.16,
    weapon_7_round: 2.38,
    weapon_8: 'Starlight Engine Replica',
    weapon_8_app: 0.14,
    weapon_8_round: 2.67,
    weapon_9: 'Peacekeeper - Specialized',
    weapon_9_app: 0.05,
    weapon_9_round: 3.0,
    weapon_10: '[Lunar] Pleniluna',
    weapon_10_app: 0.05,
    weapon_10_round: 1.0,
    artifact_1: 'Chaotic Metal, Woodpecker Electro',
    artifact_1_1: 'Chaotic Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 75.63,
    artifact_1_round: 2.74,
    artifact_2: 'Chaotic Metal, Swing Jazz',
    artifact_2_1: 'Chaotic Metal',
    artifact_2_2: 'Swing Jazz',
    artifact_2_3: '',
    artifact_2_app: 5.63,
    artifact_2_round: 2.76,
    artifact_3: 'Chaotic Metal, Hormone Punk',
    artifact_3_1: 'Chaotic Metal',
    artifact_3_2: 'Hormone Punk',
    artifact_3_3: '',
    artifact_3_app: 5.21,
    artifact_3_round: 2.69,
    artifact_4: 'Woodpecker Electro, Chaotic Metal',
    artifact_4_1: 'Woodpecker Electro',
    artifact_4_2: 'Chaotic Metal',
    artifact_4_3: '',
    artifact_4_app: 4.74,
    artifact_4_round: 2.78,
    artifact_5: 'Chaotic Metal, Flex',
    artifact_5_1: 'Chaotic Metal',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 2.7,
    artifact_5_round: 2.7,
    artifact_6: 'Chaotic Metal, Puffer Electro',
    artifact_6_1: 'Chaotic Metal',
    artifact_6_2: 'Puffer Electro',
    artifact_6_3: '',
    artifact_6_app: 2.37,
    artifact_6_round: 2.73,
    artifact_7: 'Woodpecker Electro, Flex',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Flex',
    artifact_7_3: '',
    artifact_7_app: 0.51,
    artifact_7_round: 2.75,
    artifact_8: 'Woodpecker Electro, Hormone Punk',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Hormone Punk',
    artifact_8_3: '',
    artifact_8_app: 0.42,
    artifact_8_round: 3.0,
    artifact_9: 'Chaotic Metal, Freedom Blues',
    artifact_9_1: 'Chaotic Metal',
    artifact_9_2: 'Freedom Blues',
    artifact_9_3: '',
    artifact_9_app: 0.37,
    artifact_9_round: 2.33,
    artifact_10: 'Hormone Punk, Woodpecker Electro',
    artifact_10_1: 'Hormone Punk',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: '',
    artifact_10_app: 0.33,
    artifact_10_round: 3.0,
    app_0: 77.16,
    round_0: 2.74,
    app_1: 16.84,
    round_1: 2.91,
    app_2: 3.26,
    round_2: 2.94,
    app_3: 0.28,
    round_3: 2.5,
    app_4: 0.42,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 2.05,
    round_6: 3.0,
    cons_avg: 0.38,
    sample: 2180,
    sample_app_flat: 1616
  },
  {
    char: 'Rina',
    app_rate: 20.09,
    avg_round: 2.74,
    std_dev_round: 0.54,
    role: 'Support',
    rarity: 'Standard S',
    diff: 3.13,
    diff_rounds: 0.34,
    weapon_1: 'Slice of Time',
    weapon_1_app: 55.81,
    weapon_1_round: 2.76,
    weapon_2: 'Weeping Cradle',
    weapon_2_app: 33.72,
    weapon_2_round: 2.71,
    weapon_3: 'Kaboom the Cannon',
    weapon_3_app: 3.49,
    weapon_3_round: 2.85,
    weapon_4: '[Reverb] Mark II',
    weapon_4_app: 3.2,
    weapon_4_round: 2.89,
    weapon_5: 'Unfettered Game Ball',
    weapon_5_app: 2.18,
    weapon_5_round: 2.73,
    weapon_6: '[Reverb] Mark III',
    weapon_6_app: 0.58,
    weapon_6_round: 1.75,
    weapon_7: 'The Vault',
    weapon_7_app: 0.15,
    weapon_7_round: 1.0,
    weapon_8: 'Street Superstar',
    weapon_8_app: 0.15,
    weapon_8_round: 3.0,
    weapon_9: 'Fusion Compiler',
    weapon_9_app: 0.15,
    weapon_9_round: 2.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Swing Jazz, Puffer Electro',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Puffer Electro',
    artifact_1_3: '',
    artifact_1_app: 45.93,
    artifact_1_round: 2.81,
    artifact_2: 'Swing Jazz, Freedom Blues',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 10.32,
    artifact_2_round: 2.71,
    artifact_3: 'Freedom Blues, Puffer Electro',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Puffer Electro',
    artifact_3_3: '',
    artifact_3_app: 6.83,
    artifact_3_round: 2.66,
    artifact_4: 'Freedom Blues, Swing Jazz',
    artifact_4_1: 'Freedom Blues',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 5.23,
    artifact_4_round: 2.8,
    artifact_5: 'Swing Jazz, Flex',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 3.63,
    artifact_5_round: 2.58,
    artifact_6: 'Swing Jazz, Thunder Metal',
    artifact_6_1: 'Swing Jazz',
    artifact_6_2: 'Thunder Metal',
    artifact_6_3: '',
    artifact_6_app: 2.91,
    artifact_6_round: 2.71,
    artifact_7: 'Freedom Blues, Thunder Metal',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Thunder Metal',
    artifact_7_3: '',
    artifact_7_app: 2.47,
    artifact_7_round: 2.5,
    artifact_8: 'Swing Jazz, Woodpecker Electro',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Woodpecker Electro',
    artifact_8_3: '',
    artifact_8_app: 1.89,
    artifact_8_round: 2.73,
    artifact_9: 'Swing Jazz, Shockstar Disco',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 1.16,
    artifact_9_round: 3.0,
    artifact_10: 'Thunder Metal, Puffer Electro',
    artifact_10_1: 'Thunder Metal',
    artifact_10_2: 'Puffer Electro',
    artifact_10_3: '',
    artifact_10_app: 1.02,
    artifact_10_round: 2.67,
    app_0: 60.9,
    round_0: 2.71,
    app_1: 30.09,
    round_1: 2.79,
    app_2: 6.4,
    round_2: 2.82,
    app_3: 2.18,
    round_3: 2.67,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.44,
    round_6: 1.0,
    cons_avg: 0.52,
    sample: 705,
    sample_app_flat: 544
  },
  {
    char: 'Lucy',
    app_rate: 33.11,
    avg_round: 2.74,
    std_dev_round: 0.54,
    role: 'Support',
    rarity: 'A',
    diff: 6.4,
    diff_rounds: 0.42,
    weapon_1: 'Kaboom the Cannon',
    weapon_1_app: 70.63,
    weapon_1_round: 2.74,
    weapon_2: 'Slice of Time',
    weapon_2_app: 10.2,
    weapon_2_round: 2.61,
    weapon_3: '[Reverb] Mark II',
    weapon_3_app: 5.68,
    weapon_3_round: 2.78,
    weapon_4: 'Weeping Cradle',
    weapon_4_app: 5.24,
    weapon_4_round: 2.71,
    weapon_5: 'Unfettered Game Ball',
    weapon_5_app: 2.57,
    weapon_5_round: 2.73,
    weapon_6: '[Reverb] Mark III',
    weapon_6_app: 2.22,
    weapon_6_round: 2.88,
    weapon_7: 'Bashful Demon',
    weapon_7_app: 1.69,
    weapon_7_round: 2.88,
    weapon_8: '[Reverb] Mark I',
    weapon_8_app: 0.53,
    weapon_8_round: 2.83,
    weapon_9: 'The Vault',
    weapon_9_app: 0.44,
    weapon_9_round: 2.67,
    weapon_10: 'Drill Rig - Red Axis',
    weapon_10_app: 0.09,
    weapon_10_round: 3.0,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 49.6,
    artifact_1_round: 2.75,
    artifact_2: 'Swing Jazz, Woodpecker Electro',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Woodpecker Electro',
    artifact_2_3: '',
    artifact_2_app: 8.78,
    artifact_2_round: 2.88,
    artifact_3: 'Swing Jazz, Flex',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Flex',
    artifact_3_3: '',
    artifact_3_app: 6.12,
    artifact_3_round: 2.78,
    artifact_4: 'Swing Jazz, Freedom Blues',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Freedom Blues',
    artifact_4_3: '',
    artifact_4_app: 4.35,
    artifact_4_round: 2.7,
    artifact_5: 'Swing Jazz, Inferno Metal',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Inferno Metal',
    artifact_5_3: '',
    artifact_5_app: 4.35,
    artifact_5_round: 2.86,
    artifact_6: 'Hormone Punk, Swing Jazz',
    artifact_6_1: 'Hormone Punk',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 3.28,
    artifact_6_round: 2.71,
    artifact_7: 'Swing Jazz, Shockstar Disco',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 1.42,
    artifact_7_round: 2.71,
    artifact_8: 'Hormone Punk, Swing Jazz, Flex',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: 'Flex',
    artifact_8_app: 1.33,
    artifact_8_round: 2.31,
    artifact_9: 'Freedom Blues, Swing Jazz',
    artifact_9_1: 'Freedom Blues',
    artifact_9_2: 'Swing Jazz',
    artifact_9_3: '',
    artifact_9_app: 1.24,
    artifact_9_round: 2.67,
    artifact_10: 'Swing Jazz, Puffer Electro',
    artifact_10_1: 'Swing Jazz',
    artifact_10_2: 'Puffer Electro',
    artifact_10_3: '',
    artifact_10_app: 1.24,
    artifact_10_round: 2.77,
    app_0: 14.37,
    round_0: 2.71,
    app_1: 19.88,
    round_1: 2.69,
    app_2: 22.72,
    round_2: 2.74,
    app_3: 17.66,
    round_3: 2.72,
    app_4: 10.03,
    round_4: 2.83,
    app_5: 7.1,
    round_5: 2.71,
    app_6: 8.25,
    round_6: 2.82,
    cons_avg: 2.43,
    sample: 1162,
    sample_app_flat: 1058
  },
  {
    char: 'Qingyi',
    app_rate: 61.04,
    avg_round: 2.74,
    std_dev_round: 0.53,
    role: 'Stun',
    rarity: 'Limited S',
    diff: 2.44,
    diff_rounds: 0.16,
    weapon_1: 'Ice-Jade Teapot',
    weapon_1_app: 37.8,
    weapon_1_round: 2.8,
    weapon_2: 'Steam Oven',
    weapon_2_app: 22.3,
    weapon_2_round: 2.72,
    weapon_3: 'Demara Battery Mark II',
    weapon_3_app: 15.31,
    weapon_3_round: 2.68,
    weapon_4: 'The Restrained',
    weapon_4_app: 10.01,
    weapon_4_round: 2.82,
    weapon_5: 'Hellfire Gears',
    weapon_5_app: 6.85,
    weapon_5_round: 2.75,
    weapon_6: 'Precious Fossilized Core',
    weapon_6_app: 6.07,
    weapon_6_round: 2.63,
    weapon_7: 'Six Shooter',
    weapon_7_app: 1.31,
    weapon_7_round: 2.62,
    weapon_8: '[Vortex] Arrow',
    weapon_8_app: 0.34,
    weapon_8_round: 3.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 40.67,
    artifact_1_round: 2.73,
    artifact_2: 'Shockstar Disco, Thunder Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Thunder Metal',
    artifact_2_3: '',
    artifact_2_app: 32.36,
    artifact_2_round: 2.72,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 15.31,
    artifact_3_round: 2.88,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 3.79,
    artifact_4_round: 2.7,
    artifact_5: 'Shockstar Disco, Freedom Blues',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 1.8,
    artifact_5_round: 2.66,
    artifact_6: 'Shockstar Disco, Hormone Punk',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 1.7,
    artifact_6_round: 2.75,
    artifact_7: 'Shockstar Disco, Puffer Electro',
    artifact_7_1: 'Shockstar Disco',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: '',
    artifact_7_app: 1.02,
    artifact_7_round: 3.0,
    artifact_8: 'Thunder Metal, Shockstar Disco',
    artifact_8_1: 'Thunder Metal',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 0.49,
    artifact_8_round: 2.71,
    artifact_9: 'Woodpecker Electro, Shockstar Disco',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 0.39,
    artifact_9_round: 3.0,
    artifact_10: 'Swing Jazz, Shockstar Disco',
    artifact_10_1: 'Swing Jazz',
    artifact_10_2: 'Shockstar Disco',
    artifact_10_3: '',
    artifact_10_app: 0.34,
    artifact_10_round: 2.6,
    app_0: 83.38,
    round_0: 2.74,
    app_1: 10.74,
    round_1: 2.9,
    app_2: 3.26,
    round_2: 2.91,
    app_3: 0.68,
    round_3: 2.86,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.94,
    round_6: 3.0,
    cons_avg: 0.31,
    sample: 2142,
    sample_app_flat: 1537
  },
  {
    char: 'Lycaon',
    app_rate: 41.61,
    avg_round: 2.73,
    std_dev_round: 0.52,
    role: 'Stun',
    rarity: 'Standard S',
    diff: -8.37,
    diff_rounds: 0.16,
    weapon_1: 'Steam Oven',
    weapon_1_app: 29.78,
    weapon_1_round: 2.73,
    weapon_2: 'The Restrained',
    weapon_2_app: 29.29,
    weapon_2_round: 2.76,
    weapon_3: 'Precious Fossilized Core',
    weapon_3_app: 18.21,
    weapon_3_round: 2.68,
    weapon_4: 'Hellfire Gears',
    weapon_4_app: 12.12,
    weapon_4_round: 2.78,
    weapon_5: 'Six Shooter',
    weapon_5_app: 7.2,
    weapon_5_round: 2.8,
    weapon_6: '[Vortex] Arrow',
    weapon_6_app: 2.29,
    weapon_6_round: 2.67,
    weapon_7: 'Demara Battery Mark II',
    weapon_7_app: 0.76,
    weapon_7_round: 2.67,
    weapon_8: '[Vortex] Revolver',
    weapon_8_app: 0.21,
    weapon_8_round: 2.67,
    weapon_9: 'Ice-Jade Teapot',
    weapon_9_app: 0.07,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 44.18,
    artifact_1_round: 2.75,
    artifact_2: 'Shockstar Disco, Polar Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 28.32,
    artifact_2_round: 2.67,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 9.56,
    artifact_3_round: 2.78,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 4.78,
    artifact_4_round: 2.76,
    artifact_5: 'Shockstar Disco, Freedom Blues',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 2.84,
    artifact_5_round: 2.89,
    artifact_6: 'Shockstar Disco, Hormone Punk',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 1.73,
    artifact_6_round: 2.83,
    artifact_7: 'Shockstar Disco, Puffer Electro',
    artifact_7_1: 'Shockstar Disco',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: '',
    artifact_7_app: 1.52,
    artifact_7_round: 2.62,
    artifact_8: 'Freedom Blues, Shockstar Disco',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 0.9,
    artifact_8_round: 2.86,
    artifact_9: 'Swing Jazz, Shockstar Disco',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 0.69,
    artifact_9_round: 2.8,
    artifact_10: 'Shockstar Disco, Swing Jazz, Flex',
    artifact_10_1: 'Shockstar Disco',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: 'Flex',
    artifact_10_app: 0.62,
    artifact_10_round: 3.0,
    app_0: 69.74,
    round_0: 2.73,
    app_1: 23.89,
    round_1: 2.75,
    app_2: 4.92,
    round_2: 2.74,
    app_3: 1.11,
    round_3: 2.67,
    app_4: 0.14,
    round_4: 1.0,
    app_5: 0.07,
    round_5: 1.0,
    app_6: 0.14,
    round_6: 1.0,
    cons_avg: 0.39,
    sample: 1460,
    sample_app_flat: 1019
  },
  {
    char: 'Soukaku',
    app_rate: 66.0,
    avg_round: 2.72,
    std_dev_round: 0.52,
    role: 'Support',
    rarity: 'A',
    diff: -14.37,
    diff_rounds: 0.2,
    weapon_1: 'Bashful Demon',
    weapon_1_app: 90.96,
    weapon_1_round: 2.75,
    weapon_2: 'Kaboom the Cannon',
    weapon_2_app: 2.63,
    weapon_2_round: 2.48,
    weapon_3: 'Slice of Time',
    weapon_3_app: 2.58,
    weapon_3_round: 2.4,
    weapon_4: '[Reverb] Mark II',
    weapon_4_app: 1.56,
    weapon_4_round: 2.45,
    weapon_5: 'Weeping Cradle',
    weapon_5_app: 0.71,
    weapon_5_round: 2.92,
    weapon_6: '[Reverb] Mark III',
    weapon_6_app: 0.67,
    weapon_6_round: 2.4,
    weapon_7: 'Unfettered Game Ball',
    weapon_7_app: 0.58,
    weapon_7_round: 2.54,
    weapon_8: '[Reverb] Mark I',
    weapon_8_app: 0.18,
    weapon_8_round: 2.75,
    weapon_9: 'The Brimstone',
    weapon_9_app: 0.04,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 42.43,
    artifact_1_round: 2.72,
    artifact_2: 'Swing Jazz, Polar Metal',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 11.0,
    artifact_2_round: 2.76,
    artifact_3: 'Swing Jazz, Woodpecker Electro',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 6.1,
    artifact_3_round: 2.85,
    artifact_4: 'Swing Jazz, Flex',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 5.48,
    artifact_4_round: 2.74,
    artifact_5: 'Swing Jazz, Freedom Blues',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 4.05,
    artifact_5_round: 2.76,
    artifact_6: 'Polar Metal, Woodpecker Electro',
    artifact_6_1: 'Polar Metal',
    artifact_6_2: 'Woodpecker Electro',
    artifact_6_3: '',
    artifact_6_app: 3.03,
    artifact_6_round: 2.75,
    artifact_7: 'Freedom Blues, Swing Jazz',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 1.96,
    artifact_7_round: 2.7,
    artifact_8: 'Swing Jazz, Shockstar Disco',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 1.91,
    artifact_8_round: 2.67,
    artifact_9: 'Freedom Blues, Polar Metal',
    artifact_9_1: 'Freedom Blues',
    artifact_9_2: 'Polar Metal',
    artifact_9_3: '',
    artifact_9_app: 1.78,
    artifact_9_round: 2.8,
    artifact_10: 'Hormone Punk, Polar Metal',
    artifact_10_1: 'Hormone Punk',
    artifact_10_2: 'Polar Metal',
    artifact_10_3: '',
    artifact_10_app: 1.69,
    artifact_10_round: 2.63,
    app_0: 0.53,
    round_0: 2.25,
    app_1: 1.02,
    round_1: 2.58,
    app_2: 2.32,
    round_2: 2.58,
    app_3: 3.03,
    round_3: 2.58,
    app_4: 5.92,
    round_4: 2.75,
    app_5: 7.39,
    round_5: 2.74,
    app_6: 79.79,
    round_6: 2.74,
    cons_avg: 5.54,
    sample: 2316,
    sample_app_flat: 1642
  },
  {
    char: 'Koleda',
    app_rate: 19.41,
    avg_round: 2.72,
    std_dev_round: 0.56,
    role: 'Stun',
    rarity: 'Standard S',
    diff: -1.8,
    diff_rounds: 0.3,
    weapon_1: 'Steam Oven',
    weapon_1_app: 40.66,
    weapon_1_round: 2.75,
    weapon_2: 'Hellfire Gears',
    weapon_2_app: 26.51,
    weapon_2_round: 2.74,
    weapon_3: 'Precious Fossilized Core',
    weapon_3_app: 14.76,
    weapon_3_round: 2.59,
    weapon_4: 'Six Shooter',
    weapon_4_app: 5.72,
    weapon_4_round: 2.76,
    weapon_5: 'The Restrained',
    weapon_5_app: 4.22,
    weapon_5_round: 2.76,
    weapon_6: 'Demara Battery Mark II',
    weapon_6_app: 2.86,
    weapon_6_round: 2.86,
    weapon_7: '[Vortex] Arrow',
    weapon_7_app: 2.86,
    weapon_7_round: 2.83,
    weapon_8: '[Vortex] Revolver',
    weapon_8_app: 0.6,
    weapon_8_round: 2.0,
    weapon_9: 'Ice-Jade Teapot',
    weapon_9_app: 0.15,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 60.84,
    artifact_1_round: 2.75,
    artifact_2: 'Shockstar Disco, Inferno Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Inferno Metal',
    artifact_2_3: '',
    artifact_2_app: 8.13,
    artifact_2_round: 2.6,
    artifact_3: 'Shockstar Disco, Flex',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Flex',
    artifact_3_3: '',
    artifact_3_app: 7.98,
    artifact_3_round: 2.69,
    artifact_4: 'Shockstar Disco, Woodpecker Electro',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Woodpecker Electro',
    artifact_4_3: '',
    artifact_4_app: 5.57,
    artifact_4_round: 2.7,
    artifact_5: 'Shockstar Disco, Freedom Blues',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 5.27,
    artifact_5_round: 2.61,
    artifact_6: 'Shockstar Disco, Hormone Punk',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 1.81,
    artifact_6_round: 2.62,
    artifact_7: 'Swing Jazz, Shockstar Disco',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 1.51,
    artifact_7_round: 2.89,
    artifact_8: 'Shockstar Disco, Puffer Electro',
    artifact_8_1: 'Shockstar Disco',
    artifact_8_2: 'Puffer Electro',
    artifact_8_3: '',
    artifact_8_app: 0.9,
    artifact_8_round: 2.5,
    artifact_9: 'Inferno Metal, Shockstar Disco',
    artifact_9_1: 'Inferno Metal',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 0.75,
    artifact_9_round: 2.4,
    artifact_10: 'Shockstar Disco, Swing Jazz, Flex',
    artifact_10_1: 'Shockstar Disco',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: 'Flex',
    artifact_10_app: 0.45,
    artifact_10_round: 2.67,
    app_0: 70.48,
    round_0: 2.7,
    app_1: 24.7,
    round_1: 2.77,
    app_2: 3.92,
    round_2: 2.67,
    app_3: 0.9,
    round_3: 3.0,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.0,
    round_6: 1.0,
    cons_avg: 0.35,
    sample: 681,
    sample_app_flat: 591
  },
  {
    char: 'Ellen',
    app_rate: 67.6,
    avg_round: 2.72,
    std_dev_round: 0.53,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: -13.95,
    diff_rounds: 0.18,
    weapon_1: 'Deep Sea Visitor',
    weapon_1_app: 71.88,
    weapon_1_round: 2.8,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 11.63,
    weapon_2_round: 2.55,
    weapon_3: 'Cannon Rotor',
    weapon_3_app: 4.61,
    weapon_3_round: 2.67,
    weapon_4: 'The Brimstone',
    weapon_4_app: 4.4,
    weapon_4_round: 2.73,
    weapon_5: 'Gilded Blossom',
    weapon_5_app: 3.26,
    weapon_5_round: 2.4,
    weapon_6: 'Steel Cushion',
    weapon_6_app: 2.54,
    weapon_6_round: 2.58,
    weapon_7: 'Street Superstar',
    weapon_7_app: 1.06,
    weapon_7_round: 2.43,
    weapon_8: '[Lunar] Noviluna',
    weapon_8_app: 0.17,
    weapon_8_round: 2.25,
    weapon_9: '[Lunar] Pleniluna',
    weapon_9_app: 0.13,
    weapon_9_round: 2.5,
    weapon_10: 'Riot Suppressor Mark VI',
    weapon_10_app: 0.08,
    weapon_10_round: 3.0,
    artifact_1: 'Polar Metal, Woodpecker Electro',
    artifact_1_1: 'Polar Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 52.22,
    artifact_1_round: 2.69,
    artifact_2: 'Woodpecker Electro, Polar Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 13.53,
    artifact_2_round: 2.81,
    artifact_3: 'Woodpecker Electro, Puffer Electro',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Puffer Electro',
    artifact_3_3: '',
    artifact_3_app: 12.77,
    artifact_3_round: 2.72,
    artifact_4: 'Polar Metal, Puffer Electro',
    artifact_4_1: 'Polar Metal',
    artifact_4_2: 'Puffer Electro',
    artifact_4_3: '',
    artifact_4_app: 8.92,
    artifact_4_round: 2.79,
    artifact_5: 'Polar Metal, Hormone Punk',
    artifact_5_1: 'Polar Metal',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 2.83,
    artifact_5_round: 2.85,
    artifact_6: 'Polar Metal, Flex',
    artifact_6_1: 'Polar Metal',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 1.86,
    artifact_6_round: 2.62,
    artifact_7: 'Woodpecker Electro, Hormone Punk',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 1.56,
    artifact_7_round: 2.76,
    artifact_8: 'Woodpecker Electro, Flex',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Flex',
    artifact_8_3: '',
    artifact_8_app: 1.48,
    artifact_8_round: 2.7,
    artifact_9: 'Polar Metal, Swing Jazz',
    artifact_9_1: 'Polar Metal',
    artifact_9_2: 'Swing Jazz',
    artifact_9_3: '',
    artifact_9_app: 1.06,
    artifact_9_round: 2.94,
    artifact_10: 'Puffer Electro, Woodpecker Electro',
    artifact_10_1: 'Puffer Electro',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: '',
    artifact_10_app: 1.01,
    artifact_10_round: 2.63,
    app_0: 69.43,
    round_0: 2.72,
    app_1: 15.77,
    round_1: 2.82,
    app_2: 9.26,
    round_2: 2.92,
    app_3: 1.1,
    round_3: 2.85,
    app_4: 0.34,
    round_4: 2.88,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 4.1,
    round_6: 2.99,
    cons_avg: 0.64,
    sample: 2372,
    sample_app_flat: 1636
  },
  {
    char: 'soldier-11',
    app_rate: 15.08,
    avg_round: 2.71,
    std_dev_round: 0.55,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: 0.88,
    diff_rounds: 0.36,
    weapon_1: 'Starlight Engine',
    weapon_1_app: 40.12,
    weapon_1_round: 2.7,
    weapon_2: 'The Brimstone',
    weapon_2_app: 28.98,
    weapon_2_round: 2.86,
    weapon_3: 'Cannon Rotor',
    weapon_3_app: 12.86,
    weapon_3_round: 2.7,
    weapon_4: 'Gilded Blossom',
    weapon_4_app: 7.49,
    weapon_4_round: 2.44,
    weapon_5: 'Steel Cushion',
    weapon_5_app: 3.84,
    weapon_5_round: 2.75,
    weapon_6: 'Street Superstar',
    weapon_6_app: 3.65,
    weapon_6_round: 2.33,
    weapon_7: '[Lunar] Pleniluna',
    weapon_7_app: 0.77,
    weapon_7_round: 2.25,
    weapon_8: '[Lunar] Noviluna',
    weapon_8_app: 0.38,
    weapon_8_round: 2.5,
    weapon_9: 'Drill Rig - Red Axis',
    weapon_9_app: 0.19,
    weapon_9_round: 3.0,
    weapon_10: '[Lunar] Decrescent',
    weapon_10_app: 0.19,
    weapon_10_round: 1.0,
    artifact_1: 'Inferno Metal, Woodpecker Electro',
    artifact_1_1: 'Inferno Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 65.45,
    artifact_1_round: 2.7,
    artifact_2: 'Inferno Metal, Puffer Electro',
    artifact_2_1: 'Inferno Metal',
    artifact_2_2: 'Puffer Electro',
    artifact_2_3: '',
    artifact_2_app: 10.94,
    artifact_2_round: 2.69,
    artifact_3: 'Woodpecker Electro, Inferno Metal',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Inferno Metal',
    artifact_3_3: '',
    artifact_3_app: 7.29,
    artifact_3_round: 2.79,
    artifact_4: 'Inferno Metal, Swing Jazz',
    artifact_4_1: 'Inferno Metal',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 3.45,
    artifact_4_round: 2.76,
    artifact_5: 'Inferno Metal, Flex',
    artifact_5_1: 'Inferno Metal',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 3.07,
    artifact_5_round: 2.5,
    artifact_6: 'Inferno Metal, Hormone Punk',
    artifact_6_1: 'Inferno Metal',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 1.92,
    artifact_6_round: 2.8,
    artifact_7: 'Inferno Metal, Woodpecker Electro, Flex',
    artifact_7_1: 'Inferno Metal',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: 'Flex',
    artifact_7_app: 0.77,
    artifact_7_round: 2.5,
    artifact_8: 'Woodpecker Electro, Flex',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Flex',
    artifact_8_3: '',
    artifact_8_app: 0.58,
    artifact_8_round: 2.67,
    artifact_9: 'Woodpecker Electro, Hormone Punk',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 0.58,
    artifact_9_round: 2.67,
    artifact_10: 'Inferno Metal, Freedom Blues',
    artifact_10_1: 'Inferno Metal',
    artifact_10_2: 'Freedom Blues',
    artifact_10_3: '',
    artifact_10_app: 0.58,
    artifact_10_round: 3.0,
    app_0: 72.17,
    round_0: 2.68,
    app_1: 22.26,
    round_1: 2.75,
    app_2: 4.99,
    round_2: 2.84,
    app_3: 0.38,
    round_3: 3.0,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.19,
    round_5: 3.0,
    app_6: 0.0,
    round_6: 1.0,
    cons_avg: 0.34,
    sample: 529,
    sample_app_flat: 510
  },
  {
    char: 'grace-howard',
    app_rate: 12.11,
    avg_round: 2.69,
    std_dev_round: 0.58,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: 3.45,
    diff_rounds: 0.51,
    weapon_1: 'Fusion Compiler',
    weapon_1_app: 33.33,
    weapon_1_round: 2.76,
    weapon_2: 'Weeping Gemini',
    weapon_2_app: 28.81,
    weapon_2_round: 2.72,
    weapon_3: 'Rainforest Gourmet',
    weapon_3_app: 20.95,
    weapon_3_round: 2.68,
    weapon_4: 'Electro-Lip Gloss',
    weapon_4_app: 11.67,
    weapon_4_round: 2.7,
    weapon_5: 'Roaring Ride',
    weapon_5_app: 4.05,
    weapon_5_round: 2.13,
    weapon_6: '[Magnetic Storm] Bravo',
    weapon_6_app: 0.48,
    weapon_6_round: 2.5,
    weapon_7: '',
    weapon_7_app: 0.0,
    weapon_7_round: 1.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Freedom Blues, Thunder Metal',
    artifact_1_1: 'Freedom Blues',
    artifact_1_2: 'Thunder Metal',
    artifact_1_3: '',
    artifact_1_app: 34.29,
    artifact_1_round: 2.71,
    artifact_2: 'Thunder Metal, Freedom Blues',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 31.19,
    artifact_2_round: 2.71,
    artifact_3: 'Freedom Blues, Swing Jazz',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 8.57,
    artifact_3_round: 2.7,
    artifact_4: 'Freedom Blues, Hormone Punk',
    artifact_4_1: 'Freedom Blues',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 2.86,
    artifact_4_round: 2.67,
    artifact_5: 'Thunder Metal, Swing Jazz',
    artifact_5_1: 'Thunder Metal',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: '',
    artifact_5_app: 2.62,
    artifact_5_round: 2.91,
    artifact_6: 'Thunder Metal, Flex',
    artifact_6_1: 'Thunder Metal',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 2.38,
    artifact_6_round: 2.67,
    artifact_7: 'Freedom Blues, Flex',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Flex',
    artifact_7_3: '',
    artifact_7_app: 2.38,
    artifact_7_round: 2.56,
    artifact_8: 'Freedom Blues, Woodpecker Electro',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Woodpecker Electro',
    artifact_8_3: '',
    artifact_8_app: 2.38,
    artifact_8_round: 2.78,
    artifact_9: 'Thunder Metal, Woodpecker Electro',
    artifact_9_1: 'Thunder Metal',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: '',
    artifact_9_app: 1.19,
    artifact_9_round: 2.4,
    artifact_10: 'Thunder Metal, Hormone Punk',
    artifact_10_1: 'Thunder Metal',
    artifact_10_2: 'Hormone Punk',
    artifact_10_3: '',
    artifact_10_app: 0.95,
    artifact_10_round: 2.25,
    app_0: 70.71,
    round_0: 2.66,
    app_1: 23.57,
    round_1: 2.74,
    app_2: 4.76,
    round_2: 2.88,
    app_3: 0.95,
    round_3: 3.0,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.0,
    round_6: 1.0,
    cons_avg: 0.36,
    sample: 425,
    sample_app_flat: 375
  },
  {
    char: 'anby-demara',
    app_rate: 33.37,
    avg_round: 2.67,
    std_dev_round: 0.58,
    role: 'Stun',
    rarity: 'A',
    diff: -10.13,
    diff_rounds: 0.28,
    weapon_1: 'Demara Battery Mark II',
    weapon_1_app: 64.06,
    weapon_1_round: 2.65,
    weapon_2: 'Steam Oven',
    weapon_2_app: 15.93,
    weapon_2_round: 2.71,
    weapon_3: 'Hellfire Gears',
    weapon_3_app: 7.92,
    weapon_3_round: 2.76,
    weapon_4: 'The Restrained',
    weapon_4_app: 5.52,
    weapon_4_round: 2.61,
    weapon_5: 'Precious Fossilized Core',
    weapon_5_app: 3.91,
    weapon_5_round: 2.63,
    weapon_6: '[Vortex] Arrow',
    weapon_6_app: 0.53,
    weapon_6_round: 2.0,
    weapon_7: 'Six Shooter',
    weapon_7_app: 0.36,
    weapon_7_round: 3.0,
    weapon_8: 'Ice-Jade Teapot',
    weapon_8_app: 0.18,
    weapon_8_round: 2.5,
    weapon_9: 'Drill Rig - Red Axis',
    weapon_9_app: 0.09,
    weapon_9_round: 3.0,
    weapon_10: '[Vortex] Revolver',
    weapon_10_app: 0.09,
    weapon_10_round: 3.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 51.87,
    artifact_1_round: 2.71,
    artifact_2: 'Shockstar Disco, Thunder Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Thunder Metal',
    artifact_2_3: '',
    artifact_2_app: 13.79,
    artifact_2_round: 2.51,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 9.96,
    artifact_3_round: 2.71,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 7.65,
    artifact_4_round: 2.56,
    artifact_5: 'Shockstar Disco, Freedom Blues',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 3.29,
    artifact_5_round: 2.8,
    artifact_6: 'Shockstar Disco, Puffer Electro',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Puffer Electro',
    artifact_6_3: '',
    artifact_6_app: 1.51,
    artifact_6_round: 2.58,
    artifact_7: 'Shockstar Disco, Hormone Punk',
    artifact_7_1: 'Shockstar Disco',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 1.33,
    artifact_7_round: 2.75,
    artifact_8: 'Shockstar Disco, Swing Jazz, Flex',
    artifact_8_1: 'Shockstar Disco',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: 'Flex',
    artifact_8_app: 1.07,
    artifact_8_round: 2.67,
    artifact_9: 'Shockstar Disco, Flex',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Flex',
    artifact_9_3: '',
    artifact_9_app: 0.98,
    artifact_9_round: 2.6,
    artifact_10: 'Swing Jazz, Shockstar Disco',
    artifact_10_1: 'Swing Jazz',
    artifact_10_2: 'Shockstar Disco',
    artifact_10_3: '',
    artifact_10_app: 0.53,
    artifact_10_round: 2.6,
    app_0: 3.29,
    round_0: 2.21,
    app_1: 5.96,
    round_1: 2.54,
    app_2: 12.28,
    round_2: 2.65,
    app_3: 13.7,
    round_3: 2.62,
    app_4: 11.83,
    round_4: 2.67,
    app_5: 12.1,
    round_5: 2.7,
    app_6: 40.84,
    round_6: 2.74,
    cons_avg: 4.24,
    sample: 1171,
    sample_app_flat: 992
  },
  {
    char: 'Piper',
    app_rate: 14.16,
    avg_round: 2.67,
    std_dev_round: 0.61,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: 3.44,
    diff_rounds: 0.46,
    weapon_1: 'Roaring Ride',
    weapon_1_app: 65.13,
    weapon_1_round: 2.69,
    weapon_2: 'Weeping Gemini',
    weapon_2_app: 11.13,
    weapon_2_round: 2.58,
    weapon_3: 'Rainforest Gourmet',
    weapon_3_app: 10.08,
    weapon_3_round: 2.57,
    weapon_4: 'Fusion Compiler',
    weapon_4_app: 6.51,
    weapon_4_round: 2.8,
    weapon_5: 'Electro-Lip Gloss',
    weapon_5_app: 2.31,
    weapon_5_round: 2.5,
    weapon_6: '[Magnetic Storm] Bravo',
    weapon_6_app: 0.42,
    weapon_6_round: 2.0,
    weapon_7: 'Sharpened Stinger',
    weapon_7_app: 0.42,
    weapon_7_round: 3.0,
    weapon_8: '[Magnetic Storm] Charlie',
    weapon_8_app: 0.21,
    weapon_8_round: 3.0,
    weapon_9: '[Magnetic Storm] Alpha',
    weapon_9_app: 0.21,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Fanged Metal, Freedom Blues',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 21.85,
    artifact_1_round: 2.78,
    artifact_2: 'Freedom Blues, Fanged Metal',
    artifact_2_1: 'Freedom Blues',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 21.64,
    artifact_2_round: 2.64,
    artifact_3: 'Freedom Blues, Swing Jazz',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 20.38,
    artifact_3_round: 2.63,
    artifact_4: 'Freedom Blues, Flex',
    artifact_4_1: 'Freedom Blues',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 4.41,
    artifact_4_round: 2.62,
    artifact_5: 'Freedom Blues, Hormone Punk',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 3.57,
    artifact_5_round: 2.65,
    artifact_6: 'Fanged Metal, Swing Jazz',
    artifact_6_1: 'Fanged Metal',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 2.1,
    artifact_6_round: 2.6,
    artifact_7: 'Fanged Metal, Hormone Punk',
    artifact_7_1: 'Fanged Metal',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 1.89,
    artifact_7_round: 2.88,
    artifact_8: 'Fanged Metal, Freedom Blues, Flex',
    artifact_8_1: 'Fanged Metal',
    artifact_8_2: 'Freedom Blues',
    artifact_8_3: 'Flex',
    artifact_8_app: 1.68,
    artifact_8_round: 2.75,
    artifact_9: 'Fanged Metal, Flex',
    artifact_9_1: 'Fanged Metal',
    artifact_9_2: 'Flex',
    artifact_9_3: '',
    artifact_9_app: 1.26,
    artifact_9_round: 2.5,
    artifact_10: 'Freedom Blues, Hormone Punk, Flex',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Hormone Punk',
    artifact_10_3: 'Flex',
    artifact_10_app: 0.84,
    artifact_10_round: 2.67,
    app_0: 13.87,
    round_0: 2.48,
    app_1: 23.74,
    round_1: 2.72,
    app_2: 22.27,
    round_2: 2.63,
    app_3: 17.44,
    round_3: 2.65,
    app_4: 12.18,
    round_4: 2.75,
    app_5: 3.78,
    round_5: 2.78,
    app_6: 6.72,
    round_6: 2.83,
    cons_avg: 2.29,
    sample: 497,
    sample_app_flat: 478
  },
  {
    char: 'Nekomata',
    app_rate: 8.24,
    avg_round: 2.64,
    std_dev_round: 0.62,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: 0.35,
    diff_rounds: 0.48,
    weapon_1: 'Steel Cushion',
    weapon_1_app: 35.89,
    weapon_1_round: 2.64,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 31.71,
    weapon_2_round: 2.69,
    weapon_3: 'The Brimstone',
    weapon_3_app: 10.8,
    weapon_3_round: 2.8,
    weapon_4: 'Gilded Blossom',
    weapon_4_app: 9.06,
    weapon_4_round: 2.54,
    weapon_5: 'Cannon Rotor',
    weapon_5_app: 6.27,
    weapon_5_round: 2.65,
    weapon_6: 'Street Superstar',
    weapon_6_app: 2.09,
    weapon_6_round: 2.4,
    weapon_7: 'Housekeeper',
    weapon_7_app: 1.05,
    weapon_7_round: 3.0,
    weapon_8: 'Riot Suppressor Mark VI',
    weapon_8_app: 0.7,
    weapon_8_round: 1.5,
    weapon_9: '[Lunar] Noviluna',
    weapon_9_app: 0.35,
    weapon_9_round: 2.0,
    weapon_10: 'Starlight Engine Replica',
    weapon_10_app: 0.35,
    weapon_10_round: 2.0,
    artifact_1: 'Fanged Metal, Woodpecker Electro',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 47.04,
    artifact_1_round: 2.63,
    artifact_2: 'Woodpecker Electro, Fanged Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 20.56,
    artifact_2_round: 2.76,
    artifact_3: 'Fanged Metal, Hormone Punk',
    artifact_3_1: 'Fanged Metal',
    artifact_3_2: 'Hormone Punk',
    artifact_3_3: '',
    artifact_3_app: 8.36,
    artifact_3_round: 2.73,
    artifact_4: 'Woodpecker Electro, Puffer Electro',
    artifact_4_1: 'Woodpecker Electro',
    artifact_4_2: 'Puffer Electro',
    artifact_4_3: '',
    artifact_4_app: 2.79,
    artifact_4_round: 2.86,
    artifact_5: 'Woodpecker Electro, Hormone Punk',
    artifact_5_1: 'Woodpecker Electro',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 2.44,
    artifact_5_round: 2.57,
    artifact_6: 'Fanged Metal, Flex',
    artifact_6_1: 'Fanged Metal',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 2.44,
    artifact_6_round: 2.43,
    artifact_7: 'Fanged Metal, Puffer Electro',
    artifact_7_1: 'Fanged Metal',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: '',
    artifact_7_app: 1.39,
    artifact_7_round: 2.5,
    artifact_8: 'Fanged Metal, Swing Jazz',
    artifact_8_1: 'Fanged Metal',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 1.39,
    artifact_8_round: 2.0,
    artifact_9: 'Puffer Electro, Woodpecker Electro',
    artifact_9_1: 'Puffer Electro',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: '',
    artifact_9_app: 1.39,
    artifact_9_round: 2.5,
    artifact_10: 'Fanged Metal, Hormone Punk, Woodpecker Electro',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Hormone Punk',
    artifact_10_3: 'Woodpecker Electro',
    artifact_10_app: 1.39,
    artifact_10_round: 2.25,
    app_0: 66.9,
    round_0: 2.64,
    app_1: 27.87,
    round_1: 2.64,
    app_2: 4.18,
    round_2: 2.73,
    app_3: 1.05,
    round_3: 3.0,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.0,
    round_6: 1.0,
    cons_avg: 0.39,
    sample: 289,
    sample_app_flat: 272
  },
  {
    char: 'Anton',
    app_rate: 5.5,
    avg_round: 2.64,
    std_dev_round: 0.62,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: -2.35,
    diff_rounds: 0.49,
    weapon_1: 'Drill Rig - Red Axis',
    weapon_1_app: 78.31,
    weapon_1_round: 2.63,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 7.94,
    weapon_2_round: 2.71,
    weapon_3: 'Street Superstar',
    weapon_3_app: 3.17,
    weapon_3_round: 2.83,
    weapon_4: 'Cannon Rotor',
    weapon_4_app: 2.65,
    weapon_4_round: 2.6,
    weapon_5: 'The Brimstone',
    weapon_5_app: 2.12,
    weapon_5_round: 3.0,
    weapon_6: 'Gilded Blossom',
    weapon_6_app: 2.12,
    weapon_6_round: 2.5,
    weapon_7: 'Steel Cushion',
    weapon_7_app: 1.06,
    weapon_7_round: 3.0,
    weapon_8: '[Lunar] Noviluna',
    weapon_8_app: 1.06,
    weapon_8_round: 3.0,
    weapon_9: '[Lunar] Pleniluna',
    weapon_9_app: 0.53,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Thunder Metal, Woodpecker Electro',
    artifact_1_1: 'Thunder Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 64.02,
    artifact_1_round: 2.59,
    artifact_2: 'Thunder Metal, Swing Jazz',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Swing Jazz',
    artifact_2_3: '',
    artifact_2_app: 12.7,
    artifact_2_round: 2.73,
    artifact_3: 'Woodpecker Electro, Thunder Metal',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Thunder Metal',
    artifact_3_3: '',
    artifact_3_app: 3.7,
    artifact_3_round: 2.71,
    artifact_4: 'Thunder Metal, Flex',
    artifact_4_1: 'Thunder Metal',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 3.17,
    artifact_4_round: 3.0,
    artifact_5: 'Thunder Metal, Puffer Electro',
    artifact_5_1: 'Thunder Metal',
    artifact_5_2: 'Puffer Electro',
    artifact_5_3: '',
    artifact_5_app: 3.17,
    artifact_5_round: 2.83,
    artifact_6: 'Thunder Metal, Hormone Punk',
    artifact_6_1: 'Thunder Metal',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 2.12,
    artifact_6_round: 3.0,
    artifact_7: 'Woodpecker Electro, Swing Jazz',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 2.12,
    artifact_7_round: 2.5,
    artifact_8: 'Woodpecker Electro, Flex',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Flex',
    artifact_8_3: '',
    artifact_8_app: 1.06,
    artifact_8_round: 2.5,
    artifact_9: 'Thunder Metal, Shockstar Disco',
    artifact_9_1: 'Thunder Metal',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 1.06,
    artifact_9_round: 2.0,
    artifact_10: 'Thunder Metal, Freedom Blues',
    artifact_10_1: 'Thunder Metal',
    artifact_10_2: 'Freedom Blues',
    artifact_10_3: '',
    artifact_10_app: 0.53,
    artifact_10_round: 3.0,
    app_0: 1.59,
    round_0: 2.33,
    app_1: 2.65,
    round_1: 3.0,
    app_2: 2.12,
    round_2: 2.5,
    app_3: 6.35,
    round_3: 2.64,
    app_4: 10.58,
    round_4: 2.74,
    app_5: 8.99,
    round_5: 2.53,
    app_6: 67.72,
    round_6: 2.66,
    cons_avg: 5.2,
    sample: 193,
    sample_app_flat: 176
  },
  {
    char: 'Ben',
    app_rate: 4.73,
    avg_round: 2.59,
    std_dev_round: 0.62,
    role: 'Stun',
    rarity: 'A',
    diff: 0.12,
    diff_rounds: 0.41,
    weapon_1: 'Big Cylinder',
    weapon_1_app: 81.29,
    weapon_1_round: 2.61,
    weapon_2: 'Bunny Band',
    weapon_2_app: 5.81,
    weapon_2_round: 2.44,
    weapon_3: 'Original Transmorpher',
    weapon_3_app: 5.81,
    weapon_3_round: 2.44,
    weapon_4: 'Spring Embrace',
    weapon_4_app: 3.23,
    weapon_4_round: 2.6,
    weapon_5: '[Identity] Base',
    weapon_5_app: 2.58,
    weapon_5_round: 2.25,
    weapon_6: 'Demara Battery Mark II',
    weapon_6_app: 0.65,
    weapon_6_round: 3.0,
    weapon_7: '',
    weapon_7_app: 0.0,
    weapon_7_round: 1.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 12.9,
    artifact_1_round: 2.75,
    artifact_2: 'Swing Jazz, Soul Rock',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Soul Rock',
    artifact_2_3: '',
    artifact_2_app: 10.32,
    artifact_2_round: 2.69,
    artifact_3: 'Shockstar Disco, Soul Rock',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Soul Rock',
    artifact_3_3: '',
    artifact_3_app: 8.39,
    artifact_3_round: 2.62,
    artifact_4: 'Inferno Metal, Soul Rock',
    artifact_4_1: 'Inferno Metal',
    artifact_4_2: 'Soul Rock',
    artifact_4_3: '',
    artifact_4_app: 4.52,
    artifact_4_round: 2.29,
    artifact_5: 'Shockstar Disco, Soul Rock, Swing Jazz',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Soul Rock',
    artifact_5_3: 'Swing Jazz',
    artifact_5_app: 3.87,
    artifact_5_round: 3.0,
    artifact_6: 'Soul Rock, Woodpecker Electro',
    artifact_6_1: 'Soul Rock',
    artifact_6_2: 'Woodpecker Electro',
    artifact_6_3: '',
    artifact_6_app: 3.23,
    artifact_6_round: 2.0,
    artifact_7: 'Inferno Metal, Soul Rock, Swing Jazz',
    artifact_7_1: 'Inferno Metal',
    artifact_7_2: 'Soul Rock',
    artifact_7_3: 'Swing Jazz',
    artifact_7_app: 3.23,
    artifact_7_round: 2.2,
    artifact_8: 'Soul Rock, Shockstar Disco',
    artifact_8_1: 'Soul Rock',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 2.58,
    artifact_8_round: 2.5,
    artifact_9: 'Swing Jazz, Shockstar Disco',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 1.94,
    artifact_9_round: 2.67,
    artifact_10: 'Inferno Metal, Woodpecker Electro',
    artifact_10_1: 'Inferno Metal',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: '',
    artifact_10_app: 1.94,
    artifact_10_round: 2.33,
    app_0: 1.94,
    round_0: 2.33,
    app_1: 4.52,
    round_1: 2.29,
    app_2: 11.61,
    round_2: 2.44,
    app_3: 9.03,
    round_3: 2.5,
    app_4: 7.1,
    round_4: 2.82,
    app_5: 12.26,
    round_5: 2.42,
    app_6: 53.55,
    round_6: 2.67,
    cons_avg: 4.66,
    sample: 166,
    sample_app_flat: 161
  },
  {
    char: 'Corin',
    app_rate: 4.99,
    avg_round: 2.52,
    std_dev_round: 0.69,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: 0.01,
    diff_rounds: 0.37,
    weapon_1: 'Housekeeper',
    weapon_1_app: 69.19,
    weapon_1_round: 2.5,
    weapon_2: 'Steel Cushion',
    weapon_2_app: 12.79,
    weapon_2_round: 2.63,
    weapon_3: 'Starlight Engine',
    weapon_3_app: 4.07,
    weapon_3_round: 2.71,
    weapon_4: 'Gilded Blossom',
    weapon_4_app: 3.49,
    weapon_4_round: 2.0,
    weapon_5: 'The Brimstone',
    weapon_5_app: 3.49,
    weapon_5_round: 2.5,
    weapon_6: 'Street Superstar',
    weapon_6_app: 1.74,
    weapon_6_round: 2.67,
    weapon_7: 'Cannon Rotor',
    weapon_7_app: 1.16,
    weapon_7_round: 3.0,
    weapon_8: '[Lunar] Decrescent',
    weapon_8_app: 1.16,
    weapon_8_round: 2.5,
    weapon_9: 'Demara Battery Mark II',
    weapon_9_app: 0.58,
    weapon_9_round: 1.0,
    weapon_10: '[Lunar] Noviluna',
    weapon_10_app: 0.58,
    weapon_10_round: 3.0,
    artifact_1: 'Fanged Metal, Woodpecker Electro',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 32.56,
    artifact_1_round: 2.35,
    artifact_2: 'Woodpecker Electro, Fanged Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 11.63,
    artifact_2_round: 2.94,
    artifact_3: 'Hormone Punk, Woodpecker Electro',
    artifact_3_1: 'Hormone Punk',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 5.81,
    artifact_3_round: 2.67,
    artifact_4: 'Fanged Metal, Hormone Punk, Woodpecker Electro',
    artifact_4_1: 'Fanged Metal',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: 'Woodpecker Electro',
    artifact_4_app: 5.81,
    artifact_4_round: 2.6,
    artifact_5: 'Fanged Metal, Hormone Punk',
    artifact_5_1: 'Fanged Metal',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 4.65,
    artifact_5_round: 2.86,
    artifact_6: 'Puffer Electro, Woodpecker Electro',
    artifact_6_1: 'Puffer Electro',
    artifact_6_2: 'Woodpecker Electro',
    artifact_6_3: '',
    artifact_6_app: 4.07,
    artifact_6_round: 2.29,
    artifact_7: 'Woodpecker Electro, Hormone Punk',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 2.33,
    artifact_7_round: 2.67,
    artifact_8: 'Woodpecker Electro, Swing Jazz',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 1.74,
    artifact_8_round: 2.5,
    artifact_9: 'Fanged Metal, Woodpecker Electro, Hormone Punk',
    artifact_9_1: 'Fanged Metal',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: 'Hormone Punk',
    artifact_9_app: 1.74,
    artifact_9_round: 2.67,
    artifact_10: 'Fanged Metal, Flex',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Flex',
    artifact_10_3: '',
    artifact_10_app: 1.74,
    artifact_10_round: 1.67,
    app_0: 4.65,
    round_0: 2.17,
    app_1: 4.07,
    round_1: 2.29,
    app_2: 5.81,
    round_2: 2.44,
    app_3: 10.47,
    round_3: 2.41,
    app_4: 9.88,
    round_4: 2.06,
    app_5: 5.23,
    round_5: 2.44,
    app_6: 59.88,
    round_6: 2.68,
    cons_avg: 4.72,
    sample: 175,
    sample_app_flat: 161
  },
  {
    char: 'billy-kid',
    app_rate: 2.14,
    avg_round: 2.47,
    std_dev_round: 0.74,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: -0.21,
    diff_rounds: 0.36,
    weapon_1: 'Starlight Engine Replica',
    weapon_1_app: 57.53,
    weapon_1_round: 2.45,
    weapon_2: 'Steel Cushion',
    weapon_2_app: 10.96,
    weapon_2_round: 2.88,
    weapon_3: 'Starlight Engine',
    weapon_3_app: 8.22,
    weapon_3_round: 2.0,
    weapon_4: 'Gilded Blossom',
    weapon_4_app: 5.48,
    weapon_4_round: 2.25,
    weapon_5: 'Street Superstar',
    weapon_5_app: 5.48,
    weapon_5_round: 2.0,
    weapon_6: 'The Brimstone',
    weapon_6_app: 5.48,
    weapon_6_round: 3.0,
    weapon_7: 'Riot Suppressor Mark VI',
    weapon_7_app: 1.37,
    weapon_7_round: 1.0,
    weapon_8: '[Lunar] Noviluna',
    weapon_8_app: 1.37,
    weapon_8_round: 3.0,
    weapon_9: 'Housekeeper',
    weapon_9_app: 1.37,
    weapon_9_round: 3.0,
    weapon_10: '[Lunar] Pleniluna',
    weapon_10_app: 1.37,
    weapon_10_round: 3.0,
    artifact_1: 'Woodpecker Electro, Fanged Metal',
    artifact_1_1: 'Woodpecker Electro',
    artifact_1_2: 'Fanged Metal',
    artifact_1_3: '',
    artifact_1_app: 17.81,
    artifact_1_round: 2.67,
    artifact_2: 'Puffer Electro, Woodpecker Electro',
    artifact_2_1: 'Puffer Electro',
    artifact_2_2: 'Woodpecker Electro',
    artifact_2_3: '',
    artifact_2_app: 17.81,
    artifact_2_round: 2.75,
    artifact_3: 'Woodpecker Electro, Puffer Electro',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Puffer Electro',
    artifact_3_3: '',
    artifact_3_app: 13.7,
    artifact_3_round: 2.4,
    artifact_4: 'Fanged Metal, Woodpecker Electro',
    artifact_4_1: 'Fanged Metal',
    artifact_4_2: 'Woodpecker Electro',
    artifact_4_3: '',
    artifact_4_app: 10.96,
    artifact_4_round: 1.88,
    artifact_5: 'Woodpecker Electro, Hormone Punk',
    artifact_5_1: 'Woodpecker Electro',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 4.11,
    artifact_5_round: 2.67,
    artifact_6: 'Hormone Punk, Fanged Metal',
    artifact_6_1: 'Hormone Punk',
    artifact_6_2: 'Fanged Metal',
    artifact_6_3: '',
    artifact_6_app: 4.11,
    artifact_6_round: 3.0,
    artifact_7: 'Fanged Metal, Woodpecker Electro, Hormone Punk',
    artifact_7_1: 'Fanged Metal',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: 'Hormone Punk',
    artifact_7_app: 2.74,
    artifact_7_round: 2.0,
    artifact_8: 'Hormone Punk, Woodpecker Electro',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Woodpecker Electro',
    artifact_8_3: '',
    artifact_8_app: 2.74,
    artifact_8_round: 2.5,
    artifact_9: 'Fanged Metal, Hormone Punk, Woodpecker Electro',
    artifact_9_1: 'Fanged Metal',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: 'Woodpecker Electro',
    artifact_9_app: 2.74,
    artifact_9_round: 2.0,
    artifact_10: 'Shockstar Disco, Flex',
    artifact_10_1: 'Shockstar Disco',
    artifact_10_2: 'Flex',
    artifact_10_3: '',
    artifact_10_app: 1.37,
    artifact_10_round: 3.0,
    app_0: 5.48,
    round_0: 2.25,
    app_1: 4.11,
    round_1: 2.33,
    app_2: 6.85,
    round_2: 1.6,
    app_3: 8.22,
    round_3: 2.17,
    app_4: 8.22,
    round_4: 2.4,
    app_5: 2.74,
    round_5: 3.0,
    app_6: 64.38,
    round_6: 2.65,
    cons_avg: 4.75,
    sample: 75,
    sample_app_flat: 70
  }
];
