import React, { useState } from 'react';
import { Alert, Row, Col, Accordion } from 'react-bootstrap';
import { ZZZCharactersDMG } from '../data/zzz-damage-calcs';
import { ZZZCharacter } from '../components/zzz-character';
import { ZZZStat } from '../components/zzz-stat';
import { ZZZEngine } from '../components/zzz-engine';
import { ZZZDiskSet } from '../components/zzz-disk-set';
import { YoutubeEmbed } from '../../../common/components/youtube-embed';

interface IProps {
  slug: string;
  element: string;
  onlyBurst?: boolean;
  anomaly?: boolean;
}

export const ZZZCharacterCalcs: React.FC<IProps> = ({
  slug,
  element,
  onlyBurst,
  anomaly
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dpsRanking, setDpsRanking] = useState(
    ZZZCharactersDMG.find((rankingCharacter) => {
      if (rankingCharacter.slug === slug) return true;
    })
  );

  return (
    <>
      {!dpsRanking ? (
        <></>
      ) : (
        <>
          <>
            <Alert variant="primary alert-red">
              <p>
                <strong>
                  Don't use the damage output calculations as an indication on
                  who is worth pulling or investing
                </strong>{' '}
                as those are just simulations and in day-to-day gameplay there's
                a lot variables and also the skill of the player is important.
                As usual,{' '}
                <strong>don't compare the numbers between characters</strong>.
              </p>
            </Alert>
            {slug === 'jane-doe' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    <strong>Janes Mindscape 1</strong> - The added Basic Attack,
                    Salchow Jump, provided by Jane's Mindscape 1 becomes
                    increasingly valuable as combat extends. This bonus allows
                    Jane to prolong each of her Passion States, thus magnifying
                    the benefits over longer engagements. In extended encounters
                    beyond our initial calculations, you can expect this aspect
                    of her Mindscape to offer even greater advantages.
                  </li>
                  <li>
                    <strong>Jane's Mindscape 2</strong> - The effectiveness of
                    Jane's Mindscape 2 diminishes if you cannot achieve the CRIT
                    RATE Anomaly Proficiency cap for Jane's Gnawed State. As
                    such, if this cap is not reached, the gains reflected in our
                    calculations will be relatively weaker.
                  </li>
                  <li>
                    <strong>Jane's Mindscape 6</strong> - Jane's Mindscape 6
                    value depends on the downtime of Passion State she
                    experiences in the given combat situation. In situations
                    where she would experience a lot of downtime for her Passion
                    State M6 offers substantial benefits, but in situations
                    where she has very little or no downtime it is significantly
                    weaker.
                  </li>
                </ul>
              </>
            )}
            {slug === 'corin' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Corin's Mindscape 4 doesn't have a direct impact on her
                    damage output but is incredibly valuable to her, granting
                    her some incredibly needed access to additional energy
                    regeneration. While this Mindscape doesn't increase the bar,
                    it certainly makes her more consistent to play!
                  </li>
                  <li>
                    Corin's M2 and M6 are inaccessible in almost every scenario
                    during her standard rotation except for a short window right
                    after the burst rotation for M2. For this reason we have not
                    represented them in her Mindscape calcs for the standard
                    rotation.
                  </li>
                  <li>
                    Corin's M1 isn't commonly accessible during her standard
                    combo but not impossible. For this reason we've shown the
                    damage gain you can expect to earn from it, but know that
                    during Corin's gameplay it wont always be up and when it's
                    not you'll lose the bonus listed.
                  </li>
                </ul>
                <Alert variant="primary">
                  <p>
                    Corin's burst rotation is incredibly focused around her{' '}
                    <strong>Ultimate and EX Special Attack</strong> and without
                    one or both of them she will lose an{' '}
                    <strong className="red">EXTREME</strong> portion of her
                    damage potential. The trade off for having such high burst
                    potential is that Corin's standard rotation is bad - so bad
                    you want to avoid using it in fights. Doing this causes
                    Corin to be incredibly Energy Hungry, if you want to gain
                    her EX every rotation in order to access her Burst potential
                    you'll need to make use of her mediocre standard rotation or
                    find another solution.
                  </p>
                </Alert>
              </>
            )}
            {slug === 'zhu-yuan' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Zhu Yuan's Mindscape 1 is incredibly unique and does more
                    for her than can be represented in calculations accurately.
                    This Mindscape allows Zhu Yuan to gain the quick reload
                    effect after using her Chain Attack or Ultimate, granting
                    her an immediate refill of Shells after she uses her entire
                    stock. The quick reload effect will refill 6 Shells when
                    gained through her Chain Attack and 9 through her Ultimate.
                    The impact of this Mindscape is threefold:
                  </li>
                  <ul>
                    <li>
                      it allows you to completely ignore the need to build any
                      Shells prior to any of Zhu Yuan's burst rotations as she
                      gains an additional 6 per Chain Attack used bringing the
                      total granted per chain to 9,
                    </li>
                    <li>
                      it allows Zhu Yuan to fully reload her Shotgun Shells
                      after executing any full Burst rotation where her Ultimate
                      is present granting you 9 Shells to use as you see fit
                      while you prepare for the next Stun Window (refer to Mini
                      Burst Rotation to how we recommend using these),
                    </li>
                    <li>
                      it drastically improves Zhu Yuan's ability to deal with
                      inferior enemies granting her near endless ammo as you can
                      continuously use Zhu Yuan's Chain attack on all easily
                      stunnable small fry enemies you come up against.
                    </li>
                    <li>
                      <strong>
                        Zhu Yuan can gain both Quick Reload effects at the same
                        time allowing her to consume them back to back to
                        recover 15 shells total (They stack and do not overwrite
                        each other).
                      </strong>
                    </li>
                  </ul>
                  <li>
                    Zhu Yuan's Mindscape 6's value varies depending on the fight
                    and how you play her. The damage gain of this Mindscape
                    represented below only includes the additional damage
                    multiplier Zhu Yuan's EX gains and does not include the
                    energy saving potential of the Mindscape as we are unable to
                    accurately represent the value it provides due to its
                    variable nature. You can expect Zhu Yuan to gain the bonus
                    of this Mindscape roughly every 2 or 1.5 EX skills you use,
                    significantly reducing her total Energy consumption and
                    making her more consistent to play. Treat the damage gain
                    below as the bare minimum to expect.
                  </li>
                </ul>
              </>
            )}
            {slug === 'ellen' && (
              <>
                <p>
                  <strong>Important!</strong> Ellen's Mindscape 4 has very
                  little impact on her burst damage capabilities, and only
                  minor/situational benefits for her standard rotation - both of
                  which are difficult to account for in calculations. For these
                  reasons we haven't included it in our calculations. Please
                  note that doesn't mean this Mindscape is useless - just that
                  it's value is difficult to represent in realistic and useful
                  way.
                </p>
              </>
            )}
            {slug === 'soldier-11' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Soldier 11's Mindscape 1 and 4 don't have a direct impact on
                    her damage output making their value difficult to portray in
                    the calculations below - that doesn't mean they aren't
                    useful though.
                  </li>
                  <li>
                    Soldier 11's Mindscape 6 will always be active during her
                    Burst damage rotation where it is represented accurately.
                    During her Standard Rotation however it may not always be
                    active as it relies on having used an EX, Chain or Ultimate
                    recently, for all basic attacks it is active though it will
                    in most cases provide a 25% damage increase to those
                    attacks, but when it's not active it will of course not
                    offer any benefit.
                  </li>
                </ul>
              </>
            )}
            {slug === 'nekomata' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Nekomata's Mindscape 2 doesn't have a direct impact on her
                    damage output making its value difficult to portray in the
                    calculations below - that doesn't mean they aren't useful
                    though the bonus energy can result in 1, 2 or even 3 extra
                    EX's over the course of a fight.
                  </li>
                  <li>
                    Nekomata's Mindscape 1 is reliant on her attacking enemies
                    while behind and grants her the ability to always be treated
                    as behind while attacking Stunned enemies. As the enemy is
                    stunned for the majority of the burst rotation this bonus is
                    almost always active. Additionally as Nekomata is using her
                    signature W-Engine in these calculations she also gains
                    higher benefit from the engine thanks to the permanent
                    behind status. For all these reasons while the enemy is not
                    Stunned and actively attempts to face you during the
                    standard rotation this Mindscape loses a lot of it's
                    potential unless in the hands of an incredibly skilled
                    player.
                  </li>
                  <li>
                    After unlocking Nekomata's Mindscape 4 it's recommended you
                    perform 2 EX Special's before her Ultimate in the burst
                    rotation. Also, during Nekomata's standard rotation you may
                    or may not have duration of this buff - if you have it
                    active you can expect the damage increase listed on the
                    chart, if you don't you will lose a portion or all the
                    damage gains for this Mindscape during the standard
                    rotation.
                  </li>
                  <li>
                    In these calculations we assume Nekomata's Mindscape 6 is
                    active for the full rotation listed as it has no set
                    duration after it is active and can be swiftly gained in
                    combat through a number of different ways. If you're slow to
                    ramp up this buff you can expect lower performance than
                    listed.
                  </li>
                </ul>
              </>
            )}
            {slug === 'piper' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Piper's Mindscape 4 doesn't have a direct impact on her
                    damage output but the energy it provides is invaluable in
                    accelerating her access to her next full damage combo making
                    it a huge quality of life increase.
                  </li>
                  <li>
                    Piper's Mindscape 6 extends the duration of her Power buff
                    by 4 seconds but also allows her to channel her EX Special:
                    Engine Spin for an additional 2 seconds, taking the maximum
                    possible duration from 3 seconds to 5. This can be both a
                    good and bad thing depending on the enemy you're facing and
                    Piper's build. As a general rule of thumb if extending the
                    standard 3 second spin time further allows you to trigger an
                    additional Assault activation before finishing with Really
                    Heavy.
                  </li>
                </ul>
              </>
            )}
            {slug === 'grace-howard' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Graces's Mindscape 1 and 4 don't have a direct impact on her
                    damage output but the energy they provide is incredible
                    valuable allowing her to launch more EX Specials and as a
                    result inflict her devastating Shocks more consistently. Her
                    Mindscape 1 is especially useful as it not only buffs up
                    Grace's energy recovery but also the entire teams.
                  </li>
                </ul>
              </>
            )}
            {slug === 'billy-kid' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Billy's Mindscape 1 doesn't directly increase his burst
                    damage output but does increase his ability to gain his EX
                    Special attack's allowing him more consistent access to his
                    maximum potential rotation or the potential to use EX's
                    outside the enemy stun window.
                  </li>
                  <li>
                    Billy's Mindscape 2 is incredibly valuable, just not to his
                    burst damage rotation. This Mindscape introduces more
                    opportunities while the enemy isn't stunned to perform dodge
                    counters while also increasing the damage of those counters.
                  </li>
                  <li>
                    Billy's Mindscape 6 relies on the skill of the user in order
                    to achieve the results displayed below.{' '}
                    <strong>
                      If you get knocked back or launched the value of this
                      Mindscape drops dramatically compared to what is displayed
                      (it assumes you'll maintain the buff without mistakes).
                    </strong>
                  </li>
                </ul>
              </>
            )}
            {slug === 'anton' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Anton's Mindscape 1 doesn't directly increase the damage he
                    deals during his burst rotation but does noticeably ease his
                    energy requirements allowing him to maintain high Burst Mode
                    uptime easier. This Mindscape can be more heavily played
                    into if desired by performing as many Drill Attacks as
                    possible by skipping portions of Anton's usual combo to
                    extend his Burst Mode even more.
                  </li>
                </ul>
              </>
            )}
            <Row xs={1} xl={1} className="dps-comparison">
              <Col>
                <div className="box">
                  <h5>
                    {dpsRanking.name} in 1 Target scenario -{' '}
                    <strong className="burst">Burst</strong>{' '}
                    {!onlyBurst && (
                      <>
                        and <strong className="standard">Standard</strong>{' '}
                        rotations
                      </>
                    )}
                  </h5>
                  <p className="summary">
                    Rotation time:{' '}
                    <strong className={element}>{dpsRanking.time}s</strong> for
                    Burst
                    {!onlyBurst && (
                      <>
                        {' '}
                        and{' '}
                        <strong className={element}>
                          {dpsRanking.time_standard}s
                        </strong>{' '}
                        for Standard
                        {dpsRanking.time_standard_change != 0 && (
                          <>
                            {slug === 'ellen' && (
                              <>
                                {' '}
                                (
                                <strong className={element}>
                                  {dpsRanking.time_standard_change}s
                                </strong>{' '}
                                post M1)
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                    .
                  </p>
                  {slug === 'grace-howard' && (
                    <>
                      <p>
                        <strong className="red">
                          Attention!!! Grace has an incredibly short damage
                          rotation focused around applying an incredibly large
                          Shock right at the end lasting for 10 or more seconds.
                          Grace's DPS is calculated with all damage leading up
                          to and including the Shock which will continue dealing
                          damage long after she leaves the field resulting in a
                          high DPS number for her but damage which will be
                          spread over the next character who switches ins
                          rotation.
                        </strong>
                      </p>
                    </>
                  )}
                  <div className={`simulation-container ${slug}`}>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M6</span>
                      </div>
                      <div className="chart">
                        {onlyBurst && (
                          <div className="info-2">
                            <p>{dpsRanking.name} (M6)</p>
                          </div>
                        )}
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar burst`}
                            style={{ width: '100%' }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e6.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e6.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e6 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                        {!onlyBurst && (
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar standard`}
                              style={{
                                width:
                                  (dpsRanking.dps_standard.damage_e6 /
                                    dpsRanking.dps.damage_e6) *
                                    100 +
                                  '%'
                              }}
                            ></div>
                            <div className="data smaller">
                              <span className="dps">
                                {dpsRanking.dmg_standard.damage_e6.toLocaleString()}{' '}
                                DMG (
                                {dpsRanking.dps_standard.damage_e6.toLocaleString()}{' '}
                                DPS)
                              </span>
                              <span className="percent">
                                {(
                                  (dpsRanking.dps_standard.damage_e6 /
                                    dpsRanking.dps_standard.damage) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M5</span>
                      </div>
                      <div className="chart">
                        {onlyBurst && (
                          <div className="info-2">
                            <p>{dpsRanking.name} (M5)</p>
                          </div>
                        )}
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar burst`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e5 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e5.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e5.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e5 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                        {!onlyBurst && (
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar standard`}
                              style={{
                                width:
                                  (dpsRanking.dps_standard.damage_e5 /
                                    dpsRanking.dps.damage_e6) *
                                    100 +
                                  '%'
                              }}
                            ></div>
                            <div className="data smaller">
                              <span className="dps">
                                {dpsRanking.dmg_standard.damage_e5.toLocaleString()}{' '}
                                DMG (
                                {dpsRanking.dps_standard.damage_e5.toLocaleString()}{' '}
                                DPS)
                              </span>
                              <span className="percent">
                                {(
                                  (dpsRanking.dps_standard.damage_e5 /
                                    dpsRanking.dps_standard.damage) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M4</span>
                      </div>
                      <div className="chart">
                        {onlyBurst && (
                          <div className="info-2">
                            <p>{dpsRanking.name} (M4)</p>
                          </div>
                        )}
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar burst`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e4 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e4.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e4.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e4 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                        {!onlyBurst && (
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar standard`}
                              style={{
                                width:
                                  (dpsRanking.dps_standard.damage_e4 /
                                    dpsRanking.dps.damage_e6) *
                                    100 +
                                  '%'
                              }}
                            ></div>
                            <div className="data smaller">
                              <span className="dps">
                                {dpsRanking.dmg_standard.damage_e4.toLocaleString()}{' '}
                                DMG (
                                {dpsRanking.dps_standard.damage_e4.toLocaleString()}{' '}
                                DPS)
                              </span>
                              <span className="percent">
                                {(
                                  (dpsRanking.dps_standard.damage_e4 /
                                    dpsRanking.dps_standard.damage) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M3</span>
                      </div>
                      <div className="chart">
                        {onlyBurst && (
                          <div className="info-2">
                            <p>{dpsRanking.name} (M3)</p>
                          </div>
                        )}
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar burst`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e3 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e3.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e3.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e3 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                        {!onlyBurst && (
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar standard`}
                              style={{
                                width:
                                  (dpsRanking.dps_standard.damage_e3 /
                                    dpsRanking.dps.damage_e6) *
                                    100 +
                                  '%'
                              }}
                            ></div>
                            <div className="data smaller">
                              <span className="dps">
                                {dpsRanking.dmg_standard.damage_e3.toLocaleString()}{' '}
                                DMG (
                                {dpsRanking.dps_standard.damage_e3.toLocaleString()}{' '}
                                DPS)
                              </span>
                              <span className="percent">
                                {(
                                  (dpsRanking.dps_standard.damage_e3 /
                                    dpsRanking.dps_standard.damage) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M2</span>
                      </div>
                      <div className="chart">
                        {onlyBurst && (
                          <div className="info-2">
                            <p>{dpsRanking.name} (M2)</p>
                          </div>
                        )}
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar burst`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e2 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e2.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e2.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e2 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                        {!onlyBurst && (
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar standard`}
                              style={{
                                width:
                                  (dpsRanking.dps_standard.damage_e2 /
                                    dpsRanking.dps.damage_e6) *
                                    100 +
                                  '%'
                              }}
                            ></div>
                            <div className="data smaller">
                              <span className="dps">
                                {dpsRanking.dmg_standard.damage_e2.toLocaleString()}{' '}
                                DMG (
                                {dpsRanking.dps_standard.damage_e2.toLocaleString()}{' '}
                                DPS)
                              </span>
                              <span className="percent">
                                {(
                                  (dpsRanking.dps_standard.damage_e2 /
                                    dpsRanking.dps_standard.damage) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M1</span>
                      </div>
                      <div className="chart">
                        {onlyBurst && (
                          <div className="info-2">
                            <p>{dpsRanking.name} (M1)</p>
                          </div>
                        )}
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar burst`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e1 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e1.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e1.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e1 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                        {!onlyBurst && (
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar standard`}
                              style={{
                                width:
                                  (dpsRanking.dps_standard.damage_e1 /
                                    dpsRanking.dps.damage_e6) *
                                    100 +
                                  '%'
                              }}
                            ></div>
                            <div className="data smaller">
                              <span className="dps">
                                {dpsRanking.dmg_standard.damage_e1.toLocaleString()}{' '}
                                DMG (
                                {dpsRanking.dps_standard.damage_e1.toLocaleString()}{' '}
                                DPS)
                              </span>
                              <span className="percent">
                                {(
                                  (dpsRanking.dps_standard.damage_e1 /
                                    dpsRanking.dps_standard.damage) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M0</span>
                      </div>
                      <div className="chart">
                        {onlyBurst && (
                          <div className="info-2">
                            <p>{dpsRanking.name} (M0)</p>
                          </div>
                        )}
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar burst`}
                            style={{
                              width:
                                (dpsRanking.dps.damage /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage.toLocaleString()} DMG (
                              {dpsRanking.dps.damage.toLocaleString()} DPS)
                            </span>
                            <span className="percent">100%</span>
                          </div>
                        </div>
                        {!onlyBurst && (
                          <div className="dps-bar-outside">
                            <div
                              className={`dps-bar standard`}
                              style={{
                                width:
                                  (dpsRanking.dps_standard.damage /
                                    dpsRanking.dps.damage_e6) *
                                    100 +
                                  '%'
                              }}
                            ></div>
                            <div className="data smaller">
                              <span className="dps">
                                {dpsRanking.dmg_standard.damage.toLocaleString()}{' '}
                                DMG (
                                {dpsRanking.dps_standard.damage.toLocaleString()}{' '}
                                DPS)
                              </span>
                              <span className="percent">100%</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <h5>Details about the calculations</h5>
            <Accordion className="default-look dps-details">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Rotations</Accordion.Header>
                <Accordion.Body>
                  <p>
                    <strong>
                      Here are the rotations used in the calculations.
                    </strong>
                  </p>
                  {slug === 'jane-doe' && (
                    <>
                      <blockquote>
                        <h6 className="burst">Burst rotation (During Stun)</h6>
                        <p>
                          Jane, as a versatile Anomaly character, can fit into
                          various team compositions and play styles, leading to
                          diverse burst rotation strategies. Allocating portions
                          of the stun duration or Ultimate usage to other
                          characters is a feasible approach and will alter the
                          sample rotation on which our calculations are based.
                        </p>
                        <ul>
                          <li>Chain Attack: Flowers of Sin </li>
                          <li>Dash Attack: Phantom Thrust </li>
                          <li>
                            Basic Attack: Salchow Jump (Consecutive Attack){' '}
                          </li>
                          <li>Basic Attack: Salchow Jump (Finishing Move) </li>
                          <li>EX Special Attack: Aerial Sweep - Clearout </li>
                          <li>EX Special Attack: Aerial Sweep - Clearout </li>
                          <li>Dash Attack: Phantom Thrust </li>
                          <li>Ultimate: Final Curtain </li>
                          <li>
                            Basic Attack: Salchow Jump (Consecutive Attack){' '}
                          </li>
                          <li>Basic Attack: Salchow Jump (Finishing Move)</li>
                        </ul>
                      </blockquote>
                      <blockquote>
                        <h6 className="standard">Standard Rotation</h6>
                        <p>
                          Jane’s standard rotation, much like her burst
                          rotation, is highly adaptable and varies significantly
                          based on the team composition and strategy employed.
                          The frequency and form of this rotation are further
                          influenced by the specific teammates selected and
                          their respective field time requirements. Therefore,
                          the sequence of actions used in our calculations
                          should be viewed as a general guideline rather than a
                          precise formula. The actual execution of combat
                          rotations will depend on several factors, including:
                          Passion State Management, the number and timing of
                          dodges and dodge counters performed and the roles and
                          abilities of Jane’s teammates, which can alter the
                          rotation dynamics.
                        </p>
                        <ul>
                          <li>Dodge Counter: Swift Shadow (First) </li>
                          <li>Basic Attack: Dancing Blades P3 </li>
                          <li>Basic Attack: Dancing Blades P4 </li>
                          <li>Basic Attack: Dancing Blades P5 </li>
                          <li>Basic Attack: Dancing Blades P6 </li>
                          <li>Dodge Counter: Swift Shadow Dance </li>
                          <li>Basic Attack: Dancing Blades P5 </li>
                          <li>Basic Attack: Dancing Blades P6 </li>
                          <li>
                            Basic Attack: Salchow Jump (Consecutive Attack){' '}
                          </li>
                          <li>Basic Attack: Salchow Jump (Finishing Move)</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'zhu-yuan' && (
                    <>
                      <blockquote>
                        <h6 className="burst">Burst rotation (During Stun)</h6>
                        <ul>
                          <li>Chain Attack: Eradication Mode </li>
                          <li>Quick Assist: Covering Shot </li>
                          <li>EX Special Attack: Full Barrage </li>
                          <li>
                            Basic Attack: Please Do Not Resist P1 (Ether){' '}
                          </li>
                          <li>
                            Basic Attack: Please Do Not Resist P2 (Ether){' '}
                          </li>
                          <li>
                            Basic Attack: Please Do Not Resist P3 (Ether){' '}
                          </li>
                          <li>Dash Attack: Overwhelming Firepower (Ether) </li>
                          <li>
                            Basic Attack: Please Do Not Resist P3 (Ether){' '}
                          </li>
                          <li>EX Special Attack: Full Barrage </li>
                          <li>Ultimate: Max Eradication Mode </li>
                          <li>
                            Basic Attack: Please Do Not Resist P1 (Ether){' '}
                          </li>
                          <li>
                            Basic Attack: Please Do Not Resist P2 (Ether){' '}
                          </li>
                          <li>
                            Basic Attack: Please Do Not Resist P3 (Ether){' '}
                          </li>
                          <li>Dash Attack: Overwhelming Firepower (Ether)</li>
                        </ul>
                      </blockquote>
                      <blockquote>
                        <h6 className="standard">
                          Zhu Yuan Standard Rotation (in downtime or to build 2
                          Shells required for Burst Rotation)
                        </h6>
                        <ul>
                          <li>Basic Attack: Don't Move! P1 </li>
                          <li>Dash Attack: Firepower Offensive </li>
                          <li>Basic Attack: Don't Move! P3 </li>
                          <li>Basic Attack: Don't Move! P4 </li>
                          <li>Basic Attack: Don't Move! P5 </li>
                          <li>Dash Attack: Firepower Offensive </li>
                          <li>Basic Attack: Don't Move! P3 </li>
                          <li>Basic Attack: Don't Move! P4 </li>
                          <li>Basic Attack: Don't Move! P5</li>
                        </ul>
                      </blockquote>
                      <blockquote>
                        <h6 className="standard">
                          Zhu Yuan M1+ post stun mini-burst rotation (can be
                          used only once post Burst)
                        </h6>
                        <ul>
                          <li>
                            Basic Attack: Please Do Not Resist P1 (Ether){' '}
                          </li>
                          <li>
                            Basic Attack: Please Do Not Resist P2 (Ether){' '}
                          </li>
                          <li>
                            Basic Attack: Please Do Not Resist P3 (Ether){' '}
                          </li>
                          <li>Dash Attack: Overwhelming Firepower (Ether) </li>
                          <li>Basic Attack: Please Do Not Resist P3 (Ether)</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'ellen' && (
                    <>
                      <blockquote>
                        <h6 className="burst">Burst rotation (During Stun)</h6>
                        <ul>
                          <li>Chain Attack: Avalanche </li>
                          <li>Quick Assist: Shark Sentinel </li>
                          <li>EX Special Attack: Tail Swipe </li>
                          <li>Basic Attack: Flash Freeze Trimming P3 </li>
                          <li>Ultimate: Endless Winter </li>
                          <li>EX Special Attack: Tail Swipe </li>
                          <li>EX Special Attack: Sharknami </li>
                          <li>Basic Attack: Flash Freeze Trimming P3</li>
                        </ul>
                      </blockquote>
                      <blockquote>
                        <h6 className="standard">
                          M0 Standard rotation (Downtime)
                        </h6>
                        <ul>
                          <li>Dash Attack: Arctic Ambush: Spinning Slash </li>
                          <li>Dash Attack: Arctic Ambush: Charged Scissors </li>
                          <li>Basic Attack: Flash Freeze Trimming P1 </li>
                          <li>Basic Attack: Flash Freeze Trimming P2 </li>
                          <li>Basic Attack: Flash Freeze Trimming P3 </li>
                          <li>Dash Attack: Arctic Ambush: Spinning Slash </li>
                          <li>Dash Attack: Arctic Ambush: Charged Scissors </li>
                          <li>Basic Attack: Flash Freeze Trimming P1 </li>
                          <li>Basic Attack: Flash Freeze Trimming P2 </li>
                          <li>Basic Attack: Flash Freeze Trimming P3</li>
                        </ul>
                      </blockquote>
                      <blockquote>
                        <h6 className="standard">
                          M1+ Standard rotation (Downtime)
                        </h6>
                        <ul>
                          <li>Dash Attack: Arctic Ambush: Spinning Slash </li>
                          <li>Dash Attack: Arctic Ambush: Charged Scissors </li>
                          <li>Basic Attack: Flash Freeze Trimming P1 </li>
                          <li>Basic Attack: Flash Freeze Trimming P2 </li>
                          <li>Basic Attack: Flash Freeze Trimming P3 </li>
                          <li>Basic Attack: Flash Freeze Trimming P1 </li>
                          <li>Basic Attack: Flash Freeze Trimming P2 </li>
                          <li>Basic Attack: Flash Freeze Trimming P3</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'soldier-11' && (
                    <>
                      <blockquote>
                        <h6 className="burst">Burst rotation (During Stun)</h6>
                        <p>
                          Using a sequence of Basic Attacks before Soldier 11's
                          Ultimate and EX is optimal when using her signature
                          The Brimstone. When not using it however the rotation
                          can be rearranged to move the ultimate and or EX
                          earlier to work better with some of the alternative
                          Disk Drive sets she can use.
                        </p>
                        <ul>
                          <li>Chain Attack: Uplifting Flame </li>
                          <li>Quick Assist: Covering Fire </li>
                          <li>Basic Attack: Fire Suppression P1 </li>
                          <li>Basic Attack: Fire Suppression P2 </li>
                          <li>Basic Attack: Fire Suppression P3 </li>
                          <li>Basic Attack: Fire Suppression P4 </li>
                          <li>EX Special Attack: Fervent Fire </li>
                          <li>Ultimate: Bellowing Flame </li>
                          <li>Basic Attack: Fire Suppression P1 </li>
                          <li>Basic Attack: Fire Suppression P2 </li>
                          <li>Basic Attack: Fire Suppression P3 </li>
                          <li>Basic Attack: Fire Suppression P4 </li>
                          <li>Basic Attack: Fire Suppression P1 </li>
                          <li>Basic Attack: Fire Suppression P2 </li>
                          <li>Basic Attack: Fire Suppression P3 </li>
                          <li>Basic Attack: Fire Suppression P4</li>
                        </ul>
                      </blockquote>
                      <blockquote>
                        <h6 className="standard">
                          Standard rotation (Downtime)
                        </h6>
                        <ul>
                          <li>Basic Attack: Fire Suppression P1 </li>
                          <li>Basic Attack: Fire Suppression P2 </li>
                          <li>Basic Attack: Fire Suppression P3 </li>
                          <li>Basic Attack: Fire Suppression P4 </li>
                          <li>Basic Attack: Fire Suppression P1 </li>
                          <li>Basic Attack: Fire Suppression P2 </li>
                          <li>Basic Attack: Fire Suppression P3 </li>
                          <li>Basic Attack: Fire Suppression P4</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'corin' && (
                    <>
                      <blockquote>
                        <h6 className="burst">Burst rotation (During Stun)</h6>
                        <ul>
                          <li>Chain Attack: Sorry... </li>
                          <li>
                            Quick Assist: Emergency Measures (Full Channel){' '}
                          </li>
                          <li>
                            EX Special Attack: Skirt Alert (Full Channel){' '}
                          </li>
                          <li>Ultimate: Very, Very Sorry! (Full Channel)</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'nekomata' && (
                    <>
                      <p>
                        During Nekomata's Burst rotation her Core Ability does
                        not remain active for its full duration as she only
                        gains it once at the start via a Quick Assist.
                        Reapplying the Core Passive is challenging - solo boss
                        enemies while stunned will not attack you so you cannot
                        Dodge Counter and triggering another Quick Assist takes
                        up valuable Stun duration. For this reason it's
                        imperative you fit in all your most potent attacks
                        immediately after performing a quick assist as listed
                        below.
                      </p>
                      <blockquote>
                        <h6 className="burst">Burst rotation (During Stun)</h6>
                        <ul>
                          <li>Chain Attack: Claw Swipe </li>
                          <li>Quick Assist: Cat's Paw </li>
                          <li>EX Special Attack: Super Suprise Attack! </li>
                          <li>Ultimate: Claw Smash </li>
                          <li>EX Special Attack: Super Suprise Attack! </li>
                          <li>EX Special Attack: Super Suprise Attack! </li>
                          <li>Basic Attack: Kitty Slash P1 </li>
                          <li>Basic Attack: Kitty Slash P2 </li>
                          <li>Basic Attack: Kitty Slash P3 </li>
                          <li>Basic Attack: Kitty Slash P4 </li>
                          <li>
                            Basic Attack: Kitty Slash P5 (Optionally repeat if
                            activates)
                          </li>
                        </ul>
                      </blockquote>
                      <blockquote>
                        <h6 className="standard">
                          Standard rotation (Downtime)
                        </h6>
                        <ul>
                          <li>
                            Dodge Counter: Phantom Claws (Use at least every 6
                            seconds)
                          </li>
                          <li>Basic Attack: Kitty Slash P1 </li>
                          <li>Basic Attack: Kitty Slash P2 </li>
                          <li>Basic Attack: Kitty Slash P3 </li>
                          <li>Basic Attack: Kitty Slash P4 </li>
                          <li>
                            Basic Attack: Kitty Slash P5 (Optionally repeat if
                            activates)
                          </li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'billy-kid' && (
                    <>
                      <p>
                        It's imperative that before executing Billy's EX Special
                        or Ultimate you first enter the Crouching Fire state to
                        gain the massive damage boost from his Core Passive,
                        without it Billy loses much of his potential damage.
                        Billy is capable of dealing a large amount of his total
                        damage potential in a very short period of time, the
                        rotation listed below can be shortened to end right
                        after his ultimate if higher DPS is desired and other
                        characters can make use of the remaining enemy stun
                        timer.
                      </p>
                      <blockquote>
                        <h6 className="burst">Burst rotation (During Stun)</h6>
                        <ul>
                          <li>Chain Attack: Starlight Mirage </li>
                          <li>Quick Assist: Power of Teamwork </li>
                          <li>
                            Basic Attack: Full Firepower (Standing Bullets){' '}
                          </li>
                          <li>
                            Basic Attack: Full Firepower (Crouching Fire){' '}
                          </li>
                          <li>
                            EX Special Attack: Clearance Time (During Crouching){' '}
                          </li>
                          <li>
                            EX Special Attack: Clearance Time (During Crouching){' '}
                          </li>
                          <li>
                            Ultimate: Starlight Sparkle (During Crouching){' '}
                          </li>
                          <li>Basic Attack: Full Firepower (Standing Fire) </li>
                          <li>
                            Basic Attack: Full Firepower (Standing Bullets){' '}
                          </li>
                          <li>
                            Basic Attack: Full Firepower (Crouching Fire){' '}
                          </li>
                          <li>
                            Basic Attack: Full Firepower (Crouching Bullets){' '}
                          </li>
                          <li>Basic Attack: Full Firepower (Finishing Shot)</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'anton' && (
                    <>
                      <p>
                        Anton's Burst rotation is at its strongest if you can
                        enter his Burst Mode state before the enemy becomes
                        stunned to ensure you have as much time as possible to
                        execute his strongest abilities (His EX Special does not
                        have good DPS). Additionally Anton will gain more damage
                        when paired with other characters that have stronger
                        shocks than his own. Anton's baseline is calculated
                        based on his own shocks but we've also included a
                        representation of how much damage he can get when paired
                        with stronger shock based appliers down below.
                      </p>
                      <blockquote>
                        <h6 className="burst">Burst rotation (During Stun)</h6>
                        <ul>
                          <li>Chain Attack: Go Go Go! </li>
                          <li>Quick Assist: Protective Drill (Burst Mode) </li>
                          <li>
                            Basic Attack: Enthusiastic Drills P1 (Burst Mode){' '}
                          </li>
                          <li>
                            Basic Attack: Enthusiastic Drills P2 (Burst Mode){' '}
                          </li>
                          <li>
                            Basic Attack: Enthusiastic Drills P3 (Burst Mode){' '}
                          </li>
                          <li>Ultimate: Go Go Go Go Go! </li>
                          <li>
                            Basic Attack: Enthusiastic Drills P1 (Burst Mode){' '}
                          </li>
                          <li>
                            Basic Attack: Enthusiastic Drills P2 (Burst Mode)
                          </li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'piper' && (
                    <>
                      <p>
                        As an Anomaly character Piper is not anywhere near as
                        reliant on her Ultimate as Attack characters making it
                        an optional addition if you see fit, it will increase
                        her damage but is not essential. Piper can also make use
                        of her rotation equally as well outside of the stun
                        burst window something that can be especially relevant
                        when playing her in teams without stun characters, the
                        only damage related cost of this is the sacrifice of the
                        stun damage multiplier.
                      </p>
                      <blockquote>
                        <h6 className="burst">Burst rotation (During Stun)</h6>
                        <ul>
                          <li>Chain Attack: Buckle Up </li>
                          <li>
                            Special Attack: Tire Spin (Full Channel Until EX
                            Starts)
                          </li>
                          <li>
                            EX Special Attack: Engine Spin (Full Channel){' '}
                          </li>
                          <li>EX Special Attack: Really Heavy</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'grace-howard' && (
                    <>
                      <p>
                        As an Anomaly character Grace is not anywhere near as
                        reliant on her Ultimate as Attack characters making it
                        an optional addition if you see fit, it will increase
                        her damage but is not essential. Grace can also make use
                        of her rotation equally as well outside of the stun
                        burst window something that can be especially relevant
                        when playing her in teams without stun characters, the
                        only damage related cost of this is the sacrifice of the
                        stun damage multiplier.
                      </p>
                      <blockquote>
                        <h6 className="burst">Burst rotation (During Stun)</h6>
                        <ul>
                          <li>Chain Attack: Collaborative Construction</li>
                          <li>Basic Attack: High-Pressure Spike P1</li>
                          <li>Basic Attack: High-Pressure Spike P2</li>
                          <li>Basic Attack: High-Pressure Spike P3</li>
                          <li>
                            EX Special Attack: Supercharged Obstruction Removal
                          </li>
                          <li>Basic Attack: High-Pressure Spike P4</li>
                          <li>
                            EX Special Attack: Supercharged Obstruction Removal
                          </li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Character build</Accordion.Header>
                <Accordion.Body>
                  <p>
                    <strong>
                      And here are the W-Engines and Disk Drives used in the
                      calculations.
                    </strong>
                  </p>
                  <div className="build-tips damage">
                    <div
                      className={`single-item engine without-notes ${element}`}
                    >
                      <ZZZEngine
                        name={dpsRanking.weapon}
                        superImp={dpsRanking.weapon_S}
                        mode="profile"
                      />
                    </div>
                    <div className={`single-item without-notes ${element}`}>
                      <div className="double-set">
                        <ZZZDiskSet
                          name={dpsRanking.set_1}
                          onProfile
                          setup="4"
                        />
                        <ZZZDiskSet
                          name={dpsRanking.set_2}
                          onProfile
                          setup="2"
                        />
                      </div>
                    </div>
                    <div className="main-stats">
                      <div className="box">
                        <div className="stats-inside">
                          <strong className={`${element}`}>Disk 4</strong>
                        </div>
                        <div className="list-stats">
                          <ZZZStat stat={dpsRanking.disk_4} />
                        </div>
                      </div>
                      <div className="box">
                        <div className="stats-inside">
                          <strong className={`${element}`}>Disk 5</strong>
                        </div>
                        <div className="list-stats">
                          <ZZZStat stat={dpsRanking.disk_5} />
                        </div>
                      </div>
                      <div className="box">
                        <div className="stats-inside">
                          <strong className={`${element}`}>Disk 6</strong>
                        </div>
                        <div className="list-stats">
                          <ZZZStat stat={dpsRanking.disk_6} />
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col>
                        <div className="box sub-stats damage">
                          <p>
                            <span>Substats:</span> {dpsRanking.substats}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {anomaly && (
              <>
                <br />
                <h5>Details about Anomaly</h5>
                <p>
                  If you want to learn more about Anomaly characters and how the
                  archetype works, check out this great video:
                </p>{' '}
                <Row className="video">
                  <Col xs={12} lg={6}>
                    <YoutubeEmbed embedId="bi6IJXh_-9A" />
                  </Col>
                </Row>
              </>
            )}
          </>
        </>
      )}
    </>
  );
};
