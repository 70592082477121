/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { IZZZBangboo } from '../../../common/model/graphql-types';

interface IProps {
  employee: IZZZBangboo;
  showLabel?: boolean;
}

export const ZZZBangbooIcon: React.FC<IProps> = ({ employee, showLabel }) => {
  const image = employee.smallImage
    ? getImage(employee.smallImage.localFile.childImageSharp)
    : null;
  return (
    <Link to={'/zenless/bangboo/' + employee.slug}>
      <div
        className={`avatar zzz rarity-${employee.rarity} ${
          showLabel ? showLabel : ''
        }`}
      >
        {image && (
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${employee.name}`}
          />
        )}
      </div>
      {showLabel && <span className="emp-name">{employee.name}</span>}
    </Link>
  );
};
