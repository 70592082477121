import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { IZZZEngine } from '../../../common/model/graphql-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

import './zzz-card.scss';
import { Accordion } from 'react-bootstrap';

interface IProps {
  engine: IZZZEngine;
  superImp?: string;
  percent?: string;
}

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

export const ZZZEngineProfile: React.FC<IProps> = ({ engine, superImp }) => {
  const image = engine.image
    ? getImage(engine.image.localFile.childImageSharp)
    : null;
  return (
    <>
      <Accordion className="zzz-engine profile">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className={`zzz-icon rarity-${engine.rarity}`}>
              {image && (
                <GatsbyImage
                  className="disable-transition"
                  image={image}
                  alt={`${engine.name}`}
                />
              )}
            </div>
            <span className={`zzz-set-name rarity-${engine.rarity}`}>
              {engine.name}
            </span>
            {superImp && <span className="cone-super">(S{superImp})</span>}
          </Accordion.Header>
          <Accordion.Body>
            <div className="zzz-set-content">
              <div
                className={`zzz-set-description ${
                  engine.element ? engine.element : ''
                }`}
              >
                {renderRichText(engine.description, options)}
              </div>
            </div>
            <div className="stats">
              <div className="stat">
                <div>
                  <span className="name">Base ATK (Lv 60):</span>{' '}
                  <span className="value">{engine.stats.max_atk}</span>
                </div>
              </div>
              <div className="stat">
                <div>
                  <span className="name">{engine.stats.stat} (Lv 60):</span>{' '}
                  <span className="value">{engine.stats.max_special}</span>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
