/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Accordion } from 'react-bootstrap';
import { Range, getTrackBackground } from 'react-range';

interface IProps {
  id: string;
  character_element: string;
  type?: string;
  minLevel: number;
  maxLevel: number;
}

export const ZZZSkillCustom: React.FC<IProps> = ({
  id,
  character_element,
  type,
  minLevel,
  maxLevel
}) => {
  const [currentLevel, setCurrentLevel] = useState([1]);
  const [color, setColor] = useState('#979797');
  const [lucyDMG, setLucyDMG] = useState(13.8);
  const [lucyDMGFlat, setLucyDMGFlat] = useState(13.8);

  useEffect(() => {
    if (character_element === 'Physical') {
      setColor('#f0d12b');
    } else if (character_element === 'Fire') {
      setColor('#ff5521');
    } else if (character_element === 'Ice') {
      setColor('#98eff0');
    } else if (character_element === 'Ether') {
      setColor('#fe437e');
    } else if (character_element === 'Electric') {
      setColor('#2eb6ff');
    }
  }, []);

  useEffect(() => {
    setLucyDMG(13 + Number(currentLevel) * 0.8);
    setLucyDMGFlat(40 + Number(currentLevel) * 4);
  }, [currentLevel]);

  return (
    <>
      <div className="skill-header">
        <div className={`skill-icon ${character_element}`}>
          {(type === 'core' || type === 'core_special') && (
            <StaticImage
              src="../../../../images/zzz/icons/icon_core.png"
              alt="Core"
            />
          )}
          {type === 'basic' && (
            <StaticImage
              src="../../../../images/zzz/icons/icon_basic.png"
              alt="Core"
            />
          )}
          {type === 'dodge' && (
            <StaticImage
              src="../../../../images/zzz/icons/icon_dodge.png"
              alt="Core"
            />
          )}
          {type === 'special' && (
            <StaticImage
              src="../../../../images/zzz/icons/icon_special.png"
              alt="Core"
            />
          )}
          {type === 'qte' && (
            <StaticImage
              src="../../../../images/zzz/icons/icon_qte.png"
              alt="Core"
            />
          )}
        </div>
        <div className="skill-info">
          <p className="skill-name">Cheer On!</p>
        </div>
        <div className="skill-icons"></div>
      </div>
      <div className="level-changer">
        <span className="current-level">
          Lv.{' '}
          <span className="level" style={{ color: color }}>
            {currentLevel}
          </span>
        </span>
        <div className={`level-slider`}>
          <Range
            step={1}
            min={minLevel}
            max={maxLevel}
            values={currentLevel}
            onChange={(values) => setCurrentLevel(values)}
            renderTrack={({ props, children }) => (
              <div
                role="button"
                tabIndex={0}
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: '36px',
                  display: 'flex',
                  width: '100%'
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: '5px',
                    width: '100%',
                    borderRadius: '0px',
                    background: getTrackBackground({
                      values: currentLevel,
                      colors: [color, '#484950'],
                      min: minLevel,
                      max: maxLevel
                    }),
                    alignSelf: 'center'
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '20px',
                  width: '20px',
                  borderRadius: '0px',
                  backgroundColor: '#FFF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    height: '14px',
                    width: '5px',
                    backgroundColor: isDragged ? color : '#484950'
                  }}
                />
              </div>
            )}
          />
        </div>
      </div>
      <div className={`skill-description`}>
        {id === 'Lucy_1' && (
          <>
            After using EX Special Attack, when a guard boar triggers an
            explosion, it will apply the Cheer On! status to all allies,
            Bangboo, and the guard boars themselves. While under the Cheer On!
            status, allies' ATK increases by <b>{lucyDMG.toFixed(2)}%</b> of
            Lucy's ATK +<b>{lucyDMGFlat}</b>, up to a maximum of <b>600</b>. The
            Cheer On! status lasts for <b>10s</b> when a Line Drive is hit, and{' '}
            <b>15s</b> when a Fly Ball is hit. Repeated triggers reset the
            duration.
          </>
        )}
      </div>
    </>
  );
};
