import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IZZZEngine } from '../../../common/model/graphql-types';
import { ZZZEngineCard } from './zzz-engine-card';
import { ZZZEngineProfile } from './zzz-engine-profile';

interface IProps {
  id?: string;
  unitId?: string;
  name: string;
  mode: string;
  superImp?: string;
  percent?: string;
}

export const ZZZEngine: React.FC<IProps> = ({
  id,
  name,
  mode,
  unitId,
  superImp,
  percent
}) => {
  const allZzzEngine = useStaticQuery(graphql`
    query {
      allContentfulZzzEngine {
        nodes {
          id
          engineId
          name
          rarity
          type
          element
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          description {
            raw
          }
          stats {
            stat
            base_atk
            max_atk
            base_special
            max_special
          }
        }
      }
    }
  `);
  if (!id && !name && !unitId) {
    return <div>Err</div>;
  }

  const character = allZzzEngine.allContentfulZzzEngine.nodes.find(
    (emp: IZZZEngine) =>
      id ? emp.id === id : unitId ? emp.engineId === unitId : emp.name === name
  );

  if (!character) {
    return <div>Err</div>;
  }

  return (
    <>
      {mode === 'card' && <ZZZEngineCard engine={character} />}
      {mode === 'profile' && (
        <ZZZEngineProfile
          engine={character}
          superImp={superImp}
          percent={percent}
        />
      )}
    </>
  );
};
